import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonToolbar, OverlayTrigger, Popover, Col, Container, Form, Accordion, InputGroup, Nav, Row, Tab, Modal, Button, NavDropdown, TabContainer } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import { IconMinimize, IconMaximize, IconAlignLeft, IconAlignCenter, IconAlignRight, IconBold, IconItalic, IconUnderline } from '@tabler/icons-react'
import AceEditor from "react-ace";
import Select from 'react-select'
import { createReactEditorJS } from 'react-editor-js'

import Header from '@editorjs/header'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import RawTool from '@editorjs/raw'
import ImageTool from '@editorjs/image'

import BreakLine from 'editorjs-break-line';
import FontSizeTool from './FontSize.mod';

// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-github";
// import "ace-builds/src-noconflict/ext-language_tools";

// import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";

import { render } from 'react-dom'
import EmailEditor from 'react-email-editor';

import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colTemplatesIndex } from '../Index/TemplatesTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('../Index/iconjson/smile.json');

const ReactEditorJS = createReactEditorJS()

class Delimiter {
    static get toolbox() {
        return {
            icon: '<svg width="20" height="4"><rect width="20" height="4" style="fill:rgb(0,0,0);"/></svg>',
            title: 'Horizontal Rule',
        };
    }

    constructor({ data }) {
        this.data = data || {};
    }

    render() {
        this.wrapper = document.createElement('hr');
        return this.wrapper;
    }

    save() {
        return {};
    }
}

const TemplatesEditorIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [refreshId, setRefreshId] = useState('');
    
    const [templateName, setTemplateName] = useState('')
    const [templateJson, setTemplateJson] = useState('')
    const [templateHtml, setTemplateHtml] = useState('')
    const [templateHtmlPreview, setTemplateHtmlPreview] = useState('')

    const [htmlContent, setHtmlContent] = useState('')
    const [isUseFooterCanSpamTemplate, setIsUseFooterCanSpamTemplate] = useState(false)
    const [isUseCSSInline, setIsUseCSSInline] = useState(false)

    const [editorType, setEditorType] = useState('')

    const [aceEditorValue, setAceEditorValue] = useState('')

    const [ceSnippets, setCeSnippets] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [footerCanSpamTemplate, setFooterCanSpamTemplate] = useState('')

    const [unsubscribeLink, setUnsubscribeLink] = useState('<a href="{{unsubscribe-link}}">Unsubscribe</a>')
    const [webVersionLink, setWebVersionLink] = useState('<a href="{{view-in-browser}}">View in Browser</a>')
    const [customFieldSelected, setCustomFieldSelected] = useState('')

    const [listCustomFonts, setListCustomFonts] = useState([])

    const [blocksValue, setBlocksValue] = useState({})
    const [blocksOtherSettings, setBlocksOtherSettings] = useState([])
    const [blocksContentSettings, setBlocksContentSettings] = useState([])

    const [blocksContentSettingsNodes, setBlocksContentSettingsNodes] = useState([])

    const updateBlocksContentSettings = (index, newObj) => {
        const updatedItems = [...blocksContentSettings]; // Create a copy of the array
        updatedItems[index].nodes = newObj; // Toggle the value

        console.log('NewObj', updatedItems)
        console.log('TargetChNewObj', blocksOtherSettings)

        const updatedBlocksOtherSettings = { ...blocksOtherSettings };

        const Heading1NodeValue = updatedItems?.[1]?.nodes
        const Heading2NodeValue = updatedItems?.[2]?.nodes
        const Heading3NodeValue = updatedItems?.[3]?.nodes
        const Heading4NodeValue = updatedItems?.[4]?.nodes
        const TextNodeValue = updatedItems?.[5]?.nodes
        const LinkNodeValue = updatedItems?.[6]?.nodes
        const ButtonNodeValue = updatedItems?.[7]?.nodes

        updatedBlocksOtherSettings.settings_glob_link['color'] = LinkNodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_link['font-size'] = LinkNodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_link['font-style'] = LinkNodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_link['glob-font-settings'] = LinkNodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_button['background-color'] = ButtonNodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_button['color'] = ButtonNodeValue?.[2]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_button['font-size'] = ButtonNodeValue?.[3]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_button['font-style'] = ButtonNodeValue?.[4]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_button['glob-font-settings'] = ButtonNodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_text['color'] = TextNodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_text['font-size'] = TextNodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_text['font-style'] = TextNodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_text['glob-font-settings'] = TextNodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_heading1['color'] = Heading1NodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading1['font-size'] = Heading1NodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_heading1['font-style'] = Heading1NodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading1['glob-font-settings'] = Heading1NodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_heading2['color'] = Heading2NodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading2['font-size'] = Heading2NodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_heading2['font-style'] = Heading2NodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading2['glob-font-settings'] = Heading2NodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_heading3['color'] = Heading3NodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading3['font-size'] = Heading3NodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_heading3['font-style'] = Heading3NodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading3['glob-font-settings'] = Heading3NodeValue?.[0]?.defaultValue

        updatedBlocksOtherSettings.settings_glob_heading4['color'] = Heading4NodeValue?.[1]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading4['font-size'] = Heading4NodeValue?.[2]?.defaultValue + 'px'
        updatedBlocksOtherSettings.settings_glob_heading4['font-style'] = Heading4NodeValue?.[3]?.defaultValue
        updatedBlocksOtherSettings.settings_glob_heading4['glob-font-settings'] = Heading4NodeValue?.[0]?.defaultValue

        console.log('TargetChNewObj-After', updatedBlocksOtherSettings)

        setBlocksContentSettings(updatedItems); // Update the state with the new array
        setBlocksOtherSettings(updatedBlocksOtherSettings)

        applyStyles()
    }

    const toggleIsShowing = (index, stateShowing) => {
        const updatedItems = [...blocksContentSettings]; // Create a copy of the array
        updatedItems[index].is_showing = stateShowing; // Toggle the value
        setBlocksContentSettings(updatedItems); // Update the state with the new array

        //setBlocksContentSettingsNodes
      };

    const [ceTabSelected, setCeTabSelected] = useState('content')
    const [accordionSelected, setAccordionSelected] = useState('variableandfields')

    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const [wysiwygWidth, setWysiwygWidth] = useState('limited');

    const formRef = useRef();

    const emailEditorRef = useRef(null);
    const importHtmlInputRef = useRef(null);

    const onLoad = () => {
    }

    const onReady = () => {
        // editor is ready
        console.log('onReady');
    };

    const editorCore = useRef(null)

    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance
    }, [])


    const setFontFamily = (ffamily) => {
        const ceBlocks = document.querySelectorAll('.ce-block');
        ceBlocks.forEach(ceBlock => {
            ceBlock.style.fontFamily = ffamily; // Assuming customProperties is defined elsewhere
        });
    }

    const setContentWithEditorJs = (elem, state) => {
        setWysiwygWidth(state)
    }

    const changeDefaultValueOfNode = (index, value) => {
        const blocksContentSettingsNodesCopy = [...blocksContentSettingsNodes];
        const updatedNode = { ...blocksContentSettingsNodesCopy[index] };
        updatedNode.defaultValue = value;
        blocksContentSettingsNodesCopy[index] = updatedNode;

        console.log('NVAL', blocksContentSettingsNodesCopy)

        setBlocksContentSettingsNodes(blocksContentSettingsNodesCopy);
    }

    const onAceEditorChange = (newValue) => {
        console.log("change", newValue);
        // setHtmlContent(newValue)
        setAceEditorValue(newValue)

        setTemplateHtml(newValue)
        setTemplateJson(newValue)

        if(isUseFooterCanSpamTemplate){
            //footerCanSpamTemplate
            const newHtmlPreview = newValue + footerCanSpamTemplate
            setHtmlContent(newHtmlPreview)
            setTemplateHtmlPreview(newHtmlPreview)
        }else{
            setHtmlContent(newValue)
            setTemplateHtmlPreview(newValue)
        }
    }

    const handleButtonImportHtmlClick = () => {
        if (importHtmlInputRef.current) {
            importHtmlInputRef.current.click();
        } else {
            console.error("Input ref is not available.");
        }
    };

    const handleImportHtmlChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let fileReader = new FileReader();
            fileReader.onload = function() {
                setAceEditorValue(fileReader.result)
                setHtmlContent(fileReader.result);
            };
            fileReader.readAsText(file);
        }
    }

    const saveTemplate = async(elem) => {

        // console.log('Template Name', templateName)

        if(editorType == 'customhtml'){
            executeSubmitForm()
        }else if(editorType == 'wysiwyg'){
            var savedData = await editorCore.current.save();
            savedData = JSON.stringify(savedData, null, 2)

            console.log('savedData', savedData)

            setTemplateJson(savedData)

            setTimeout(() => {
                executeSubmitForm()
            }, 300);
        }else{
            emailEditorRef.current.editor.exportHtml((uldata) => {
                const { design, html } = uldata;
                    
                var exportJSON = JSON.stringify(design, null, 2);
    
                setTemplateHtml(html)
                setTemplateJson(exportJSON)
    
                setTimeout(() => {
                    executeSubmitForm()
                }, 300);
                
            });
        }

        

        
    }


    const handleSavedSettingsPopover = (elem, index) => {
        elem.preventDefault();
        const formData = new FormData(elem.target);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        console.log('ELEMDATA', data)
    }

    const executeSubmitForm = () => {
        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

       
        data['auth'] = localStorage.getItem('rgd_token')
        data['services'] = localStorage.getItem('svc_token')
        data['blocks_config'] = blocksContentSettings

        console.log(data);

        console.log('blocksContentSettings', blocksContentSettings)

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'template/save';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            // blocks_config: JSON.stringify(blocksContentSettings)
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){

                history.push('/templates');

            }else{
                Swal.fire(
                    'Analyzing Error',
                    resJson?.message,
                    'error'
                )
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();
        executeSubmitForm()
    };

    const getInfo = async(id) => {

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'templates/info';

            const apiParams = {
                'auth': localStorage.getItem('rgd_token'),
                'services': localStorage.getItem('svc_token'),
                'id': id
            }
        
            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){
                const info = result?.info

                setRefreshId(new Date().getTime().toString())
                setTemplateName(info?.name)
                setEditorType(info?.editor)
                setCeSnippets(info?.snippets)
                setCustomFields(info?.fields)
                setFooterCanSpamTemplate(info?.footer_can_spam)
                setListCustomFonts(info?.list_fonts)
                setBlocksValue(info?.blocks_value)
                setBlocksOtherSettings(info?.blocks_settings)
                setBlocksContentSettings(info?.content_settings)

                // setBlocksContentSettingsNodes()

                console.log('SNIPPETS', info?.snippets)
                
                const jsonValueOrig = info?.json;

                if(jsonValueOrig != null){

                    if(info?.editor == 'unlayer'){
                        // const jsonObjValue = JSON.parse(jsonValueOrig)

                        setTimeout(() => {
                            emailEditorRef.current.editor.loadDesign(jsonValueOrig);
                        }, 1000);
                    }else if(info?.editor == 'customhtml'){
                        // const jsonObjValue = JSON.parse(jsonValueOrig) 

                        console.log('jsonObjValue', jsonValueOrig)

                        setAceEditorValue(jsonValueOrig?.konten_email)
                        setIsUseCSSInline((jsonValueOrig?.state_use_css_inline == 1) ? true : false)
                        setIsUseFooterCanSpamTemplate((jsonValueOrig?.state_use_footer_auto == 1) ? true : false)

                        if(jsonValueOrig?.state_use_footer_auto == 1){
                            //footerCanSpamTemplate
                            const newHtmlPreview = jsonValueOrig?.konten_email + info?.footer_can_spam
                            setHtmlContent(newHtmlPreview)
                            setTemplateHtmlPreview(newHtmlPreview)
                        }else{
                            setHtmlContent(jsonValueOrig?.konten_email)
                            setTemplateHtmlPreview(jsonValueOrig?.konten_email)
                        }
                    }

                }
                
            }
        }
        
    }


    // useEffect(() => {
    //     console.log('ChangesetTemplateName', templateName)
    // })

    useEffect(() => {
        toggleCollapsedNav(true);
        playerRef.current?.playFromBeginning();
        getInfo(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'EmailAja Builder',
            custom_element_right: <div className='me-3'>
                <Form.Control type='text' name='template_name_trigger' defaultValue={templateName} id='template_name_trigger' onKeyUp={(e) => {
                      setTemplateName(e?.target?.value)
                      console.log('TVAL', e?.target?.value)
                }} className='template_name_trigger' placeholder='Template Name'/>
            </div>,
            action_right: {
                title: 'Save Changed',
                classlists: 'btn btn-primary btn-md fs-6',
                icons: '',
                onclick: saveTemplate
            }
        }));
    }, [refreshId])

    const customStyle = `
        .hk-pg-wrapper {
            padding-bottom: 58px !important;
        }
    `;

    const styleTag = document.createElement('style');
    styleTag.innerHTML = customStyle
    document.head.appendChild(styleTag);

    var settingGlobPreferences = {}
    try{
        if(typeof blocksOtherSettings?.settings_glob_preferences == 'object'){
            settingGlobPreferences = blocksOtherSettings?.settings_glob_preferences
        }else{
            settingGlobPreferences = JSON.parse(blocksOtherSettings?.settings_glob_preferences)
        }
    }catch(erx){}

    var settingGlobText = {}
    try{
        if(typeof blocksOtherSettings?.settings_glob_text == 'object'){
            settingGlobText = blocksOtherSettings?.settings_glob_text
        }else{
            settingGlobText = JSON.parse(blocksOtherSettings?.settings_glob_text)
        }
        
    }catch(erx){}

    const applyStyles = () => {
        document.querySelectorAll('.ce-paragraph a').forEach(element => {

            let $settings_glob_paragraf_use = null;
            try {
                if(typeof blocksOtherSettings?.settings_glob_link == 'object'){
                    $settings_glob_paragraf_use = blocksOtherSettings?.settings_glob_link
                }else{
                    $settings_glob_paragraf_use = JSON.parse(blocksOtherSettings?.settings_glob_link)
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }

            if ($settings_glob_paragraf_use) {
                const fontStyle = $settings_glob_paragraf_use?.['font-style']
                const color = $settings_glob_paragraf_use?.['color']
                const fontSize = $settings_glob_paragraf_use?.['font-size']
                const globFontSettings = $settings_glob_paragraf_use?.['glob-font-settings']

                if (fontSize) {
                    element.style.fontSize = fontSize + 'px';
                }
                if (color) {
                    element.style.color = color;
                }
                if (globFontSettings) {
                    element.style.fontFamily = globFontSettings;
                }

                switch (fontStyle) {
                    case 'bold':
                        element.style.fontWeight = 'bold';
                        element.style.textDecoration = 'none';
                        element.style.fontStyle = 'normal';
                        break;
                    case 'italic':
                        element.style.fontStyle = 'italic';
                        element.style.textDecoration = 'none';
                        break;
                    case 'underline':
                        element.style.textDecoration = 'underline';
                        element.style.fontStyle = 'normal';
                        break;
                    default:
                        break;
                }
            }
        });

        document.querySelectorAll('.ce-paragraph').forEach(element => {

            let $settings_glob_paragraf_use = null;
            try {
                if(typeof blocksOtherSettings?.settings_glob_text == 'object'){
                    $settings_glob_paragraf_use = blocksOtherSettings?.settings_glob_text
                }else{
                    $settings_glob_paragraf_use = JSON.parse(blocksOtherSettings?.settings_glob_text)
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }

            if ($settings_glob_paragraf_use) {
                const fontStyle = $settings_glob_paragraf_use?.['font-style']
                const color = $settings_glob_paragraf_use?.['color']
                const fontSize = $settings_glob_paragraf_use?.['font-size']
                const globFontSettings = $settings_glob_paragraf_use?.['glob-font-settings']

                if (fontSize) {
                    element.style.fontSize = fontSize + 'px';
                }
                if (color) {
                    element.style.color = color;
                }
                if (globFontSettings) {
                    element.style.fontFamily = globFontSettings;
                }

                switch (fontStyle) {
                    case 'bold':
                        element.style.fontWeight = 'bold';
                        element.style.textDecoration = 'none';
                        break;
                    case 'italic':
                        element.style.fontStyle = 'italic';
                        element.style.textDecoration = 'none';
                        break;
                    case 'underline':
                        element.style.textDecoration = 'underline';
                        break;
                    default:
                        break;
                }
            }
        });

        document.querySelectorAll('.ce-header').forEach(element => {

            let settingsGlobHeadingUse = null;

            if (element.tagName === 'H1') {
                try {
                    if(typeof blocksOtherSettings?.settings_glob_heading1 == 'object'){
                        settingsGlobHeadingUse = blocksOtherSettings?.settings_glob_heading1
                    }else{
                        settingsGlobHeadingUse = JSON.parse(blocksOtherSettings?.settings_glob_heading1)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else if (element.tagName === 'H2') {
                try {
                    if(typeof blocksOtherSettings?.settings_glob_heading2 == 'object'){
                        settingsGlobHeadingUse = blocksOtherSettings?.settings_glob_heading2
                    }else{
                        settingsGlobHeadingUse = JSON.parse(blocksOtherSettings?.settings_glob_heading2)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else if (element.tagName === 'H3') {
                try {
                    if(typeof blocksOtherSettings?.settings_glob_heading3 == 'object'){
                        settingsGlobHeadingUse = blocksOtherSettings?.settings_glob_heading3
                    }else{
                        settingsGlobHeadingUse = JSON.parse(blocksOtherSettings?.settings_glob_heading3)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else if (element.tagName === 'H4') {
                try {
                    if(typeof blocksOtherSettings?.settings_glob_heading4 == 'object'){
                        settingsGlobHeadingUse = blocksOtherSettings?.settings_glob_heading4
                    }else{
                        settingsGlobHeadingUse = JSON.parse(blocksOtherSettings?.settings_glob_heading4)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            }

            if ((typeof settingsGlobHeadingUse != 'undefined') && (settingsGlobHeadingUse != null) && (settingsGlobHeadingUse != '')) {
                
                const fontStyle = settingsGlobHeadingUse?.['font-style']

                if (settingsGlobHeadingUse?.['font-size']) {
                    const fontSize = parseInt(settingsGlobHeadingUse?.['font-size']);
                    if (!isNaN(fontSize)) {
                      element.style.fontSize = fontSize;
                    }
                }
                
                // Check for color property
                if (settingsGlobHeadingUse?.['color']) {
                    element.style.color = settingsGlobHeadingUse?.['color'];
                }
            
                // Check for glob-font-settings property
                if (settingsGlobHeadingUse?.['glob-font-settings']) {
                    element.style.fontFamily = settingsGlobHeadingUse?.['glob-font-settings'];
                }

                switch (fontStyle) {
                    case 'bold':
                        element.style.fontWeight = 'bold';
                        element.style.textDecoration = 'none';
                        break;
                    case 'italic':
                        element.style.fontStyle = 'italic';
                        element.style.textDecoration = 'none';
                        break;
                    case 'underline':
                        element.style.textDecoration = 'underline';
                        break;
                    default:
                        break;
                }

            }
        });
    };

    // console.log('settingGlobPreferences', settingGlobPreferences)
    // console.log('TypeOff', typeof blocksOtherSettings?.settings_glob_preferences)

    return (<div className='d-flex h-100'>
        <Form ref={formRef} onSubmit={handleSubmitAddManual} >
            <Form.Control type='hidden' name='id' value={id} id='id' className='id'/>
            <Form.Control type='hidden' name='template_name' value={templateName} className='template_name' id='template_name' />
            <Form.Control as="textarea" style={{display: 'none'}} name='template_json' value={templateJson} className='template_json' id='template_json'/>
            <Form.Control as="textarea" style={{display: 'none'}} name='template_html' value={templateHtml} className='template_html' id='template_html'/>
            <Form.Control as="textarea" style={{display: 'none'}} name='template_html_preview' value={templateHtmlPreview} className='template_html_preview' id='template_html_preview'/>
            <Form.Control type='hidden' value={(isUseCSSInline) ? '1' : '0'} name='state_use_css_inline' className='state_use_css_inline' id='state_use_css_inline'/>
            <Form.Control type='hidden' value={(isUseFooterCanSpamTemplate) ? '1' : '0'} name='state_use_footer_auto' className='state_use_footer_auto' id='state_use_footer_auto'/>
            {/* <Button type='submit' name='uhuy' className='uhuy' value={'1'}>Uhuy</Button> */}
        </Form>

        {(editorType == 'wysiwyg') ? <>
        <div className='parent-frame-editorjs custom-editor-page'>
            <div className='frame-editorjs' style={{'background' : settingGlobPreferences?.['background-color'], textAlign: settingGlobPreferences?.['content-align'], fontFamily: settingGlobText?.['glob-font-settings']}}>
                <div id='editorjs' style={{background : '#fff', width: '688px'}} className={(wysiwygWidth == 'full_width') ? 'fullwidth' : ''}>
                    <ReactEditorJS onInitialize={handleInitialize} onReady={(instance) => {
                        applyStyles()
                    }} tools={{ 
                        fontSize: FontSizeTool,
                        header: {
                            class: Header,
                            inlineToolbar: true,
                            config: {
                                levels: [1, 2, 3, 4], // Specify the allowed heading levels
                                defaultLevel: 1, // Set the default heading level
                            },
                        },
                        list: List,
                        table: Table,
                        raw: RawTool,
                        delimiter: Delimiter,
                        breakLine: {
                            class: BreakLine,
                            inlineToolbar: true,
                            shortcut: 'CMD+SHIFT+ENTER',
                        },
                        image: {
                            class: ImageTool,
                            config: {
                                endpoints: {
                                    byFile: '', // Your backend file uploader endpoint
                                },
                                additionalRequestData: {
                                    id: '',
                                }
                            }
            
                        }
                     }} defaultValue={blocksValue} />
                </div>
            </div>
            <div className='settings-editorjs'>
                <div className='accordion-body'>
                    <div className='form-frame'>
                        <label>EMAIL WIDTH</label>
                        <div className='parent-email-width-selector'>
                            <div onClick={(elem) => setContentWithEditorJs(elem, 'limited')} className={classNames("child-email-width-selector", ((wysiwygWidth == 'limited') ? 'active' : ''))} data-state="limited">
                                <IconMinimize/>
                                <b>Limited</b>
                            </div>
                            <div onClick={(elem) => setContentWithEditorJs(elem, 'full_width')} className={classNames("child-email-width-selector", ((wysiwygWidth == 'full_width') ? 'active' : ''))} data-state="full_width">
                                <IconMaximize/>
                                <b>Full Width</b>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='form-frame mb-3'>
                        <label>FONT</label>
                        <div className='choose-font-settings'>
                            {/* <Form.Select>
                                {listCustomFonts.map((value, index) => {
                                    return (<option value={value?.id} style={{fontFamily: value?.value}}>{value?.name}</option>)
                                })}
                            </Form.Select> */}
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isSearchable={true}
                                onChange={(elem) => {
                                    console.log('ETARGET', elem)
                                    setFontFamily(elem?.ffamily)
                                }}
                                name="glob-font-settings"
                                options={listCustomFonts}
                                styles={{
                                    option: (styles, state) => ({
                                      ...styles,
                                      cursor: 'pointer',
                                    }),
                                    control: (styles) => ({
                                      ...styles,
                                      cursor: 'pointer',
                                    })
                                }}
                            />
                            <small>If an email client doesn't support web fonts, we'll use a similar standard font instead.</small>
                        </div>
                    </div>
                    <div className='form-frame'>
                        <label>CONTENT SETTINGS</label>
                        <ButtonToolbar>
                            
                        </ButtonToolbar>
                        <ul className="list-content-settings-parent">

                            {blocksContentSettings.map((value, index) => {
                                return (
                                    <OverlayTrigger trigger="click" show={value?.is_showing} onExited={(elem) => {
                                        toggleIsShowing(index, false)
                                        console.log('RootCloseTrigger')
                                    }} rootClose={true} placement="left" overlay={(
                                        <Popover id="popover-onClick">
                                            <Popover.Header as="h3">{value?.title}</Popover.Header>
                                            <Popover.Body>
                                                <Form onSubmit={(eform) => {
                                                    handleSavedSettingsPopover(eform, index)
                                                }}>
                                                     <div className='settings-popover'>
                                                        {blocksContentSettingsNodes?.map((vNode, iNode) => {
                                                            if(vNode?.type == 'colorpicker'){
                                                                return (<div className='child-settings-popover'>
                                                                    <label className='csp-label'>{vNode?.title}</label>
                                                                    <div className='edit-popover-color-picker'>
                                                                        <Form.Control
                                                                            type="color"
                                                                            id={vNode?.attributes?.id}
                                                                            name={vNode?.attributes?.name}
                                                                            title="Choose your color"
                                                                            defaultValue={vNode?.defaultValue}
                                                                            onChange={(e) => {
                                                                                changeDefaultValueOfNode(iNode, e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>)
                                                            }else if(vNode?.type == 'alignselector'){
                                                                return (<div className='child-settings-popover'>
                                                                    <label className='csp-label'>{vNode?.title}</label>
                                                                    <div className='btn-group buttons separated' role="group">
                                                                        <button type='button' className={(vNode?.defaultValue == 'left') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                            changeDefaultValueOfNode(iNode, 'left')
                                                                        }} data-fstyle="left"><IconAlignLeft size={18}/></button>
                                                                        <button type='button' className={(vNode?.defaultValue == 'center') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                            changeDefaultValueOfNode(iNode, 'center')
                                                                        }} data-fstyle="center"><IconAlignCenter size={18}/></button>
                                                                        <button type='button' className={(vNode?.defaultValue == 'right') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                            changeDefaultValueOfNode(iNode, 'right')
                                                                        }} data-fstyle="right"><IconAlignRight size={18}/></button>
                                                                    </div>
                                                                </div>)
                                                            }else if(vNode?.type == 'fontstyle'){
                                                                return (<div className='child-settings-popover'>
                                                                    <label className='csp-label'>{vNode?.title}</label>
                                                                    <div className='btn-group buttons separated' role="group">
                                                                        <button type='button' className={(vNode?.defaultValue == 'bold') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                           changeDefaultValueOfNode(iNode, 'bold')
                                                                        }} data-fstyle="bold"><IconBold size={18}/></button>
                                                                        <button type='button' className={(vNode?.defaultValue == 'italic') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                            changeDefaultValueOfNode(iNode, 'italic')
                                                                        }} data-fstyle="italic"><IconItalic size={18}/></button>
                                                                        <button type='button' className={(vNode?.defaultValue == 'underline') ? 'btn selected' : 'btn'} style={{'width' : '30%'}}  onClick={(elem) => {
                                                                            changeDefaultValueOfNode(iNode, 'underline')
                                                                        }} data-fstyle="underline"><IconUnderline size={18}/></button>
                                                                    </div>
                                                                </div>)
                                                            }else if(vNode?.type == 'fontsize'){
                                                                return (<div className='child-settings-popover'>
                                                                    <label className='csp-label'>{vNode?.title}</label>
                                                                    <div className='edit-popover-font-size'>
                                                                        <div className='input-step'>
                                                                            <button type="button" onClick={(el) => {
                                                                                changeDefaultValueOfNode(iNode, (parseInt(vNode?.defaultValue) - 1))
                                                                            }} className="minus">–</button>
                                                                            <input type="number" className="font-size no-spinner" name="font-size" value={vNode?.defaultValue} defaultValue={vNode?.defaultValue} min={vNode?.attributes?.min} max={vNode?.attributes?.max}/>
                                                                            <button onClick={(el) => {
                                                                                changeDefaultValueOfNode(iNode, (parseInt(vNode?.defaultValue) + 1))
                                                                            }} type="button" className="plus">+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            }else if(vNode?.type == 'fontfamily'){
                                                                return (<div className='child-settings-popover'>
                                                                    <label className='csp-label'>{vNode?.title}</label>
                                                                    <div className='edit-popover-select'>
                                                                        <Form.Select className={vNode?.attributes?.classes} onChange={(e) => {
                                                                            changeDefaultValueOfNode(iNode, e?.target?.value)
                                                                        }} id={vNode?.attributes?.id} name={vNode?.attributes?.name}>
                                                                            {vNode?.options?.map((vFont, index) => {
                                                                                return (<option value={vFont?.value} selected={(vNode?.defaultValue == vFont?.value) ? true : false}>{vFont?.label}</option>)
                                                                            })}
                                                                        </Form.Select>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        })}
                                                        <div className='popover-footer'>
                                                            <Button onClick={(el) => {
                                                                toggleIsShowing(index, false)
                                                            }} className='btn btn-light btn-sm text-dark waves-effect waves-light'>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={(el) => {
                                                                toggleIsShowing(index, false)
                                                                updateBlocksContentSettings(index, blocksContentSettingsNodes)
                                                                console.log('blocksContentSettingsNodes', blocksContentSettingsNodes)
                                                                console.log('blocksContentSettings', blocksContentSettings)
                                                            }} type='submit' name='savepopoversettings' value={1} className='btn btn-success btn-sm waves-effect waves-light'>
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <li className="list-content-settings-item" onClick={(elem) => {
                                            if(!value?.is_showing){
                                                toggleIsShowing(index, true)

                                                blocksContentSettings.map((ksp, isp) => {
                                                    if(isp != index){
                                                        toggleIsShowing(isp, false)
                                                    }
                                                })
    
                                                setBlocksContentSettingsNodes(value?.nodes)
                                            }else{
                                                toggleIsShowing(index, false)
                                            }
                                           
                                        }} data-settings="preferences">
                                            <div className="icon-settings">
                                                <div dangerouslySetInnerHTML={{ __html: value?.svg }} />
                                            </div>
                                            <span className="desc-icon-settings">{value?.title}</span>
                                        </li>
                                    </OverlayTrigger>
                                )
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </> : <></>}
       
       {(editorType == 'customhtml') ? <>
            <div className='custom-html-editor-blocks'>
                <div className='custom-html-preview'>
                    <iframe id="content-builder-iframe" srcDoc={htmlContent} frameBorder="0" width="100%" height="100%" scrolling="yes"></iframe>
                </div>
                <div className='custom-html-editor'>
                    <div className="heeadline" style={{display: 'none'}}>
                        <h3>Code Editor</h3>
                    </div>
                    <TabContainer defaultActiveKey={ceTabSelected} >
                        <Nav variant="pills" className="nav nav-pills nav-justified menu-blocks-tabs-default arrow-navtabs nav-warning bg-light mb-3">
                            <Nav.Item>
                                <Nav.Link eventKey="content">Content</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="snippets">Snippets</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="settings">Settings</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className='h-100vh'>
                            <Tab.Pane eventKey="content" className='content-blocks-tabs'>
                                <div className='content-blocks-child parent-frame-editor-code-inner'>
                                    <div className='frame-editor-code-inner'>
                                        <label>CUSTOM HTML CODE</label>
                                        <AceEditor
                                            mode="html"
                                            theme="github"
                                            onChange={onAceEditorChange}
                                            name="ace_editor_custom_html"
                                            defaultValue={aceEditorValue}
                                            readOnly={false}
                                            editorProps={{ $blockScrolling: false }}
                                        />
                                    </div>
                                    <div className='footer-frame-editor-code-inner'>
                                        <Button id='import-from-html' onClick={handleButtonImportHtmlClick} className='btn btn-secondary btn-label waves-effect fw-semibold waves-light button--importhtml'>
                                            Import HTML From File
                                        </Button>
                                        <input
                                            type="file"
                                            accept=".html"
                                            style={{'display' : 'none'}}
                                            onChange={handleImportHtmlChange}
                                            ref={importHtmlInputRef}// Hide the input element
                                        />
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="snippets" className='content-blocks-tabs'>
                                <div className='content-blocks-child'>
                                    <Accordion defaultActiveKey={accordionSelected}>
                                        <Accordion.Item eventKey="variableandfields">
                                            <Accordion.Header>Variables and fields</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='form-frame mb-3'>
                                                    <label>FIELDS</label>
                                                    <div className='input-group'>
                                                        <Form.Select onChange={(elem) => {
                                                            setCustomFieldSelected(elem.target.value)
                                                        }} name='custom-fields' className='custom-fields' id='custom-user-fields'>
                                                            {customFields.map((value, index) => {
                                                                return (<option value={value?.name}>{value?.value}</option>)
                                                            })}
                                                        </Form.Select>
                                                        <Button onClick={(elem) => {
                                                            navigator.clipboard.writeText(customFieldSelected)
                                                            .then(() => {
                                                                elem.target.innerHTML = 'Copied'
                                                                setTimeout(() => {
                                                                    elem.target.innerHTML = 'Copy'
                                                                }, 1500);
                                                            })
                                                            .catch(error => {
                                                                console.error('Error copying text to clipboard: ', error);
                                                            });
                                                        }} className='btn btn-warning text-dark shadow-none' data-snippetsid="unsubscribe-links-snippets" type="button" id="button-unsubscribe-links">Copy</Button>
                                                    </div>
                                                </div>
                                                <div className='form-frame mb-3'>
                                                    <label>UNSUBSCRIBE LINK</label>
                                                    <div className='input-group'>
                                                        <Form.Control value={unsubscribeLink} name='unsubscribe-links-snippets' type='text' id='unsubscribe-links-snippets' className='unsubscribe-links-snippets noborder' aria-label='unsubscribe-links-snippets'/>
                                                        <Button onClick={(elem) => {
                                                            navigator.clipboard.writeText(unsubscribeLink)
                                                            .then(() => {
                                                                elem.target.innerHTML = 'Copied'
                                                                setTimeout(() => {
                                                                    elem.target.innerHTML = 'Copy'
                                                                }, 1500);
                                                            })
                                                            .catch(error => {
                                                                console.error('Error copying text to clipboard: ', error);
                                                            });
                                                        }} className='btn btn-warning text-dark shadow-none' data-snippetsid="unsubscribe-links-snippets" type="button" id="button-unsubscribe-links">Copy</Button>
                                                    </div>
                                                </div>
                                                <div className='form-frame mb-3'>
                                                    <label>WEB VERSION LINK</label>
                                                    <div className='input-group'>
                                                        <Form.Control value={webVersionLink} name='unsubscribe-links-snippets' type='text' id='view-in-browser-snippets' className='view-in-browser-snippets noborder' aria-label='view-in-browser-snippets'/>
                                                        <Button onClick={(elem) => {
                                                            navigator.clipboard.writeText(webVersionLink)
                                                            .then(() => {
                                                                elem.target.innerHTML = 'Copied'
                                                                setTimeout(() => {
                                                                    elem.target.innerHTML = 'Copy'
                                                                }, 1500);
                                                            })
                                                            .catch(error => {
                                                                console.error('Error copying text to clipboard: ', error);
                                                            });
                                                        }} className='btn btn-warning text-dark shadow-none' data-snippetsid="unsubscribe-links-snippets" type="button" id="button-view-in-browser">Copy</Button>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="htmlsnippets">
                                            <Accordion.Header>HTML Snippets</Accordion.Header>
                                            <Accordion.Body className='html-snippets-template'>
                                                {ceSnippets.map((value, index) => {
                                                    return (<div className='form-frame mb-3'>
                                                    <div className='label-with-icon'>
                                                        <label>{value?.title}</label>
                                                    </div>
                                                    <div className='frame-editorace-multiples'>
                                                        <Button onClick={(elem) => {
                                                            navigator.clipboard.writeText(value?.snippets)
                                                            .then(() => {
                                                                elem.target.innerHTML = 'Copied'
                                                                setTimeout(() => {
                                                                    elem.target.innerHTML = 'Copy'
                                                                }, 1500);
                                                            })
                                                            .catch(error => {
                                                                console.error('Error copying text to clipboard: ', error);
                                                            });
                                                        }} className='btn btn-outline-primary btn-sm btn-copy-editorace'>Copy</Button>
                                                        <AceEditor
                                                            mode="html"
                                                            theme="github"
                                                            name={value?.id}
                                                            defaultValue={value?.snippets}
                                                            readOnly={true}
                                                            className='editorace-multiples'
                                                            editorProps={{ $blockScrolling: false }}
                                                        />
                                                    </div>
                                                </div>)
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="settings">
                                <div className='customhtml-parent-settings-general'>
                                    <h3 className="mb-3">TEMPLATE SETTINGS</h3>
                                    <div className='customhtml-child-settings-general mb-3'>
                                        <div className="form-check form-check-warning">
                                            <Form.Control defaultChecked={(isUseCSSInline) ? true : false} onChange={(e) => {setIsUseCSSInline(e.target.checked)}} className="form-check-input use_css_auto_inline" type="checkbox" id="use_css_auto_inline" defaultValue="1"/>
                                            <label className="form-check-label" htmlFor="use_css_auto_inline">
                                                Automatic CSS inline
                                            </label>
                                        </div>
                                        <p htmlFor="use_css_auto_inline" className='desc-settings-general'>Some email clients strip out CSS in style blocks. Using our inliner will apply all of your CSS (except media queries) as inline styles.</p>
                                    </div>
                                    <div className='customhtml-child-settings-general mb-3'>
                                        <div className="form-check form-check-warning">
                                            <Form.Control defaultChecked={(isUseFooterCanSpamTemplate) ? true : false} onChange={(e) => {
                                                setIsUseFooterCanSpamTemplate(e.target.checked)
                                                if(e.target.checked){
                                                    //footerCanSpamTemplate
                                                    const newHtmlPreview = aceEditorValue + footerCanSpamTemplate
                                                    setHtmlContent(newHtmlPreview)
                                                    setTemplateHtmlPreview(newHtmlPreview)
                                                }else{
                                                    setHtmlContent(aceEditorValue)
                                                    setTemplateHtmlPreview(aceEditorValue)
                                                }
                                            }} className="form-check-input use_auto_footer_canspam" type="checkbox" id="use_auto_footer_canspam" defaultValue="1"/>
                                            <label className="form-check-label" htmlFor="use_auto_footer_canspam">
                                                Automatically add footer
                                            </label>
                                        </div>
                                        <p className='desc-settings-general'>This option will automatically add a footer to the bottom of your email with an unsubscribe and other elements that are required to comply with CAN-SPAM laws.</p>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </TabContainer>
                </div>
            </div>
       </> : <></>}

       {(editorType == 'unlayer') ? 
            <EmailEditor
                options={{
                    projectId: '215324',
                    displayMode: 'email',
                    fonts: {
                        showDefaultFonts: true,
                        customFonts: [
                            
                            {
                                label: "Comic Sans",
                                value: "'Comic Sans MS', cursive, sans-serif",
                            },
                            {
                                label: "DotGothic16",
                                value: "'DotGothic16',cursive",
                                url: "https://fonts.googleapis.com/css?family=DotGothic16",
                            },
                        ],
                    },
                }}
                editorId='215324'
                minHeight={'100vh'}
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
            />
       : <></>}
        
    </div>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TemplatesEditorIndex);