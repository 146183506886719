import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colTemplatesIndex } from '../Index/TemplatesTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const TemplatesIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const formRef = useRef();

    const [show, setShow] = useState(false)

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'templates/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const customhandlers = (data) => {
        //any handler if data loaded
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        const authValue = apiParams?.auth
        const servicesValue = apiParams?.services

        if (!data["auth"]) {
            data["auth"] = authValue;
        } else {
            if (!Array.isArray(data["auth"])) {
                data["auth"] = [data["auth"]];
            }
            data["auth"].push(authValue);
        }
        
        if (!data["services"]) {
            data["services"] = servicesValue;
        } else {
            if (!Array.isArray(data["services"])) {
                data["services"] = [data["services"]];
            }
            data["services"].push(servicesValue);
        }
        
        // Process form data, send AJAX request, etc.
        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'template/create';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){
                setShow(false)
                history.push('/templates/editor/' + resJson?.id);
            }else{
                Swal.fire(
                    'Analyzing Error',
                    resJson?.message,
                    'error'
                )
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Templates',
            actions: {
                title: 'New Template',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: (e) => {
                    setShow(true)
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='p-3 pt-0 mt-3'>
                <div className='p-3 bg-warning bg-opacity-10 rounded w-75 mb-3'>
                    <b className='fs-5 text-dark'>What is Template?</b>
                    <p className='mt-2 fs-7 text-dark'>Templates are pre-created emails that you can use for Autoresponders or Automation purposes, such as birthday greeting emails,<br/>email series, national holiday announcements, etc.</p>
                </div>
                <YogiTabler
                    column={colTemplatesIndex}
                    rowData={rowData}
                    rowsPerPage={10}
                    rowSelection={true}
                    markStarred={false}
                    searchQuery={searchTerm}
                    classes="nowrap w-100 mb-4"
                    api_endpoint={apiEndpoint}
                    api_params={apiParams}
                    customhandlers={customhandlers}
                    emptydataconfig={{
                        heading: 'You have not sent any templates yet',
                        subheading: "Don't be shy to create new templates here. Everything that's been sent will appear here.",
                        actions: {
                            className: 'btn btn-primary',
                            title: 'New Template',
                            onclick: (elem) => {
                                setShow(true)
                            }
                        }
                    }}
                    sortFields={[
                        {
                            title: 'Email',
                            field: 'email'    
                        },
                        {
                            title: 'Status',
                            field: 'status'    
                        }
                    ]}
                    responsive
                />

            </div>

            														
            <Modal.Dialog>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-5'>Create New Template</Modal.Title>
                    </Modal.Header>
                    <Form ref={formRef} onSubmit={handleSubmitAddManual} >
                        <Modal.Body>
                            <Form.Group controlId="validataionTemplateName">
                                <Form.Label htmlFor="template_name">Template Name</Form.Label>
                                <Form.Control type="text" name='template_name' id="template_name" aria-describedby="templateNameHelpBlock" placeholder='Your Template Name' required={true}/>
                            </Form.Group>
                            <div className='mb-2 mt-3 form-frame'>
                                <div className='min-popup l-buttons sm'>
                                    <div className={classNames("c-buttons", ((selectedEditor == 'unlayer') ? 'active' : ''))} onClick={(el) => ChooseEditor('unlayer')}>
                                        <div className='d-flex gap-2'>
                                            <Form.Check type="radio" defaultChecked={((selectedEditor == 'unlayer') ? true : false)} className='drag_drop_selector' name='drag_drop_selector' defaultValue={'unlayer'}/>
                                            <h2>Drag & drop editor</h2>
                                        </div>
                                        <p className='mb-0'>Our new editor is the perfect tool for creating amazing designs, whether you're a beginner or an expert.</p>
                                    </div>
                                    <div className={classNames("c-buttons", ((selectedEditor == 'wysiwyg') ? 'active' : ''))} onClick={(el) => ChooseEditor('wysiwyg')}>
                                        <div className='d-flex gap-2'>
                                            <Form.Check type="radio" defaultChecked={((selectedEditor == 'wysiwyg') ? true : false)} className='drag_drop_selector' name='drag_drop_selector' defaultValue={'wysiwyg'}/>
                                            <h2>Rich text editor</h2>
                                        </div>
                                        <p className='mb-0'>Enhance your writing experience with our seamless inline editing feature, integrated into our rich-text editor, along with a content blocks.</p>
                                    </div>
                                    <div className={classNames("c-buttons", ((selectedEditor == 'customhtml') ? 'active' : ''))} onClick={(el) => ChooseEditor('customhtml')}>
                                        <div className='d-flex gap-2'>
                                            <Form.Check type="radio" defaultChecked={((selectedEditor == 'customhtml') ? true : false)} className='drag_drop_selector' name='drag_drop_selector' defaultValue={'customhtml'}/>
                                            <h2>Custom HTML editor</h2>
                                        </div>
                                        <p className='mb-0'>Embrace the power of HTML and create truly unique emails that perfectly align with your brand identity.</p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='fs-7' variant="primary" type='submit' name='create_new_template' value={1}>
                                Create New Template
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Modal.Dialog>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TemplatesIndex);