class FontSizeTool {
    static title = 'Font Size';
    isDropDownOpen = false;
    togglingCallback = null;
    emptyString = '&nbsp;&nbsp';
    fontSizeDropDown = 'font-size-dropdown';
  
    static get sanitize() {
      return {
        font: {
          size: true,
          face: true
        },
      };
    }
    static get isInline() {
      return true;
    }
    commandName = 'fontSize';
  
    CSS = {
      button: 'ce-inline-tool',
      buttonActive: 'ce-font-size-tool--active',
      buttonModifier: 'ce-inline-tool--font',
  
    }
    nodes = {
      button: undefined
    }
    selectedFontSize = null;
  
    selectionList = undefined;
  
    buttonWrapperText = undefined;
  
    createSvg = undefined;
  
    make(tagName, classNames = null) {
      const el = document.createElement(tagName);
  
      if (Array.isArray(classNames)) {
        el.classList.add(...classNames);
      } else if (classNames) {
        el.classList.add(classNames);
      }
      return el;
    }
  
    createButton() {
      this.nodes.button = this.make('button', [this.CSS.button, this.CSS.buttonModifier]);
      this.nodes.button.type = 'button';
      this.nodes.button.setAttribute('id', 'fontSizeBtn');
      this.getFontSizeForButton();
      this.createSvg = this.svg('toggler-down', 13, 13);
      this.nodes.button.appendChild(this.createSvg);
    }
    getFontSizeForButton() {
      this.buttonWrapperText = this.make('div', 'button-wrapper-text');
      const displaySelectedFontSize = this.make('div');
      displaySelectedFontSize.setAttribute('id', this.fontSizeDropDown)
      displaySelectedFontSize.innerHTML = this.emptyString;
      this.buttonWrapperText.append(displaySelectedFontSize);
      this.nodes.button.append(this.buttonWrapperText);
    }
  
    addFontSizeOptions() {
      const fontSizeList = [
        { label: '10', value: '10' },
        { label: '14', value: '14' },
        { label: '16', value: '16' },
        { label: '18', value: '18' },
        { label: '24', value: '24' },
        { label: '32', value: '32' },
        { label: '48', value: '48' }
      ];
      this.selectionList = this.make('div', 'selectionList');
      const selectionListWrapper = this.make('div', 'selection-list-wrapper');
  
      for (const fontSize of fontSizeList) {
        const option = this.make('div');
        option.setAttribute('value', fontSize.value);
        option.setAttribute('id', fontSize.value);
        option.classList.add('selection-list-option');
        if ((document.getElementById(this.fontSizeDropDown).innerHTML === fontSize.label) || (this.selectedFontSize === fontSize.value)) {
          option.classList.add('selection-list-option-active');
        }
        option.innerHTML = fontSize.label + ' px';
        selectionListWrapper.append(option);
      }
      this.selectionList.append(selectionListWrapper);
      this.nodes.button.append(this.selectionList);
      this.selectionList.addEventListener('click', this.toggleFontSizeSelector);
  
      setTimeout(() => {
        if (typeof this.togglingCallback === 'function') {
          this.togglingCallback(true);
        }
      }, 50);
    };
  
    toggleFontSizeSelector = (event) => {
      this.selectedFontSize = event.target.id;
      this.toggle();
  
      var element = document.querySelector('.ct--top');
      if (element.classList.contains('ct--shown')) {
        element.classList.remove('ct--shown');
      }
    }
  
    removeFontSizeOptions() {
      if (this.selectionList) {
        this.isDropDownOpen = false;
        this.selectionList = this.selectionList.remove();
      }
      if (typeof this.togglingCallback === 'function') {
        this.togglingCallback(false);
      }
    }
  
    render() {
      this.createButton();
      // Change the event listener to delegate to the button
      this.nodes.button.addEventListener('click', this.toggleDropDown);
      return this.nodes.button;
    }
  
    toggleDropDown = ($event) => {
  
      // $event.stopPropagation();
  
      // ... rest of the code
      if ((($event.target).id === this.fontSizeDropDown || $event.target.parentNode.id === 'fontSizeBtn' || $event.target.id === 'fontSizeBtn')) {
        this.toggle((toolbarOpened) => {
          if (toolbarOpened) {
            this.isDropDownOpen = true;
            // Set focus back to the button
            this.nodes.button.focus();
          }
        })
      }
    }
    toggle(togglingCallback) {
      if (!this.isDropDownOpen && togglingCallback) {
        // console.log('Masuk A')
        this.addFontSizeOptions();
      } else {
        // console.log('Masuk B')
        this.removeFontSizeOptions();
      }
      if (typeof togglingCallback === 'function') {
        this.togglingCallback = togglingCallback;
      }
    }
  
    surround(range) {
      if (this.selectedFontSize) {
        console.log('A: ' + this.selectedFontSize)
        this.replaceFontSizeInWrapperContent(this.selectedFontSize)
        // document.execCommand('fontSize', false, this.selectedFontSize);
      }
    }
  
    getComputedFontStyle(node) {
  
      // console.log('Node', node)
  
      // return this.selectedFontSize
      if(node != null){
        if(node.parentElement != null){
          console.log('NODE', node.parentElement)
          return window.getComputedStyle(node.parentElement, null).getPropertyValue('font-size');
        }else{
          return null
        }
      }else{
        return null
      }
     
    };
    
    checkState(selection) {
      const isActive = document.queryCommandState('span');
      let anchoredElementFontSize = this.getComputedFontStyle(selection.anchorNode);
      const focusedElementFontSize = this.getComputedFontStyle(selection.focusNode);
  
      console.log('anchoredElementFontSize', anchoredElementFontSize)
  
      if(anchoredElementFontSize != null){
        if (anchoredElementFontSize === focusedElementFontSize) {
          anchoredElementFontSize = anchoredElementFontSize.slice(0, anchoredElementFontSize.indexOf('p'));
          const elementContainsDecimalValue = anchoredElementFontSize.indexOf('.');
          if (elementContainsDecimalValue !== -1) {
            anchoredElementFontSize = anchoredElementFontSize.slice(0, anchoredElementFontSize.indexOf('.'));
          }
          this.replaceFontSizeInWrapper(anchoredElementFontSize);
        }
        else {
          const emptyWrapper = this.emptyString;
          this.replaceFontSizeInWrapper(emptyWrapper);
        }
      }
     
      return isActive;
    }
  
  replaceFontSizeInWrapperContent(selectedFontSize) {
    if (window.getSelection().rangeCount > 0) {
        // Get the selected range
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
  
        // Check if the selected range is already within a <span>
        const existingSpan = this.getExistingSpan(range);
  
        if (existingSpan) {
            // Modify the existing <span> element
            this.modifySpan(existingSpan, range, selectedFontSize);
        } else {
            // Create a span element with the desired font size style
            const newSpan = document.createElement('span');
            newSpan.style.fontSize = selectedFontSize + 'px';
  
            // Wrap the selected range with the new span element
            this.wrapRangeWithSpan(range, newSpan);
        }
  
        // Clear the selection
        selection.removeAllRanges();
    }
  }
  
  
  wrapRangeWithSpan(range, spanElement) {
    // Clone the range to avoid modifying the original range
    const clonedRange = range.cloneRange();
  
    // Surround the cloned range with the new span element
    clonedRange.surroundContents(spanElement);
  
    // Collapse the range to the end to avoid overlapping issues
    clonedRange.collapse(false);
  
    // Replace the original range with the modified cloned range
    range.deleteContents();
    range.insertNode(clonedRange.extractContents());
  }
  
  modifySpan(spanElement, range, selectedFontSize) {
    // Create a document fragment to hold the content of the range
    const fragment = range.extractContents();
  
    // Create a new span with the desired font size
    const newSpan = document.createElement('span');
    newSpan.style.fontSize = selectedFontSize + 'px';
  
    // Append the fragment to the new span
    newSpan.appendChild(fragment);
  
    // Replace the existing span with the new span
    spanElement.parentNode.replaceChild(newSpan, spanElement);
  }
  
  
  getExistingSpan(range) {
    // Check if the selected range is within a <span> element
    let container = range.commonAncestorContainer;
  
    while (container) {
        if (container.nodeType === 1 && container.tagName.toLowerCase() === 'span') {
            return container;
        }
        container = container.parentNode;
    }
  
    return null;
  }
  
    replaceFontSizeInWrapper(size) {
      const displaySelectedFontSize = document.getElementById(this.fontSizeDropDown);
      displaySelectedFontSize.innerHTML = size;
    }
  
    clear() {
      this.toggle();
      this.selectedFontSize = null;
    }
  
    svg(name, width = 14, height = 14) {
      const icon = document.createElement('i');
  
      icon.classList.add('ri-arrow-drop-down-line');
      icon.setAttribute('width', width + 'px');
      icon.setAttribute('height', height + 'px');
      icon.setAttribute('style', 'font-size: 20px')
  
      return icon;
    }
}

export default FontSizeTool;