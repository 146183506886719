import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Card, Button, ButtonGroup } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { IconChevronLeft, IconUserFilled } from '@tabler/icons-react'
import { updateTopNavTitle } from '../../../redux/action/Header';

import YogiTabler from '../../../components/@yogi-data-table';
import { colSubscribers } from '../Index/AudicencesTableData';

import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkProgressBar from '../../../components/@hk-progressbar/HkProgressBar';

const ListViewAudience = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [pageTitle, setPageTitle] = useState('...')

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/subscribers';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
        'id': id
    }

    const customhandlers = (data) => {
        const TpageTitle = data?.informations?.title;
        setPageTitle(TpageTitle)
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: pageTitle,
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            },
            action_right: {
                title: 'Add Audience',
                classlists: 'btn btn-primary btn-sm',
                icons: null,
                onclick: (el) => {
                    history.push('/create-audience/' + id);
                }
            }
        }));
    }, [pageTitle])

    useEffect(() => {
        toggleCollapsedNav(false);
    }, [])

    return (<>
        <div className='mt-4'>
            <YogiTabler
                column={colSubscribers}
                rowData={rowData}
                rowsPerPage={10}
                rowSelection={true}
                markStarred={false}
                searchQuery={searchTerm}
                classes="nowrap w-100 mb-4"
                api_endpoint={apiEndpoint}
                api_params={apiParams}
                customhandlers={customhandlers}
                emptydataconfig={{
                    heading: 'You have not sent any audiences yet',
                    subheading: "Don't be shy to create new audience here. Everything that's been sent will appear here.",
                    actions: {
                        className: 'btn btn-primary',
                        title: 'Add Audience',
                        onclick: (elem) => {
                            history.push('/create-audience/' + id);
                        }
                    }
                }}
                sortFields={[
                    {
                        title: 'Name',
                        field: 'name'    
                    },
                    {
                        title: 'Active Audiences',
                        field: 'active_audience'    
                    },
                    {
                        title: 'Open Rate',
                        field: 'open_rate'    
                    },
                    {
                        title: 'Click Rate',
                        field: 'click_rate'    
                    },
                    {
                        title: 'Date Create',
                        field: 'date_create'    
                    }
                ]}
                responsive
            />
        </div>
    </>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(ListViewAudience);