import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const AccountSettingsIndex = ({ navCollapsed, toggleCollapsedNav }) => {

    const dispatch = useDispatch();
    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState('businessprofile');
    const [rowData, setRowData] = useState([]);


    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Account Settings',
            actions: null
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        toggleCollapsedNav(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Container>
                <Tab.Container activeKey={key}>
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-0">
                        <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                            <Nav.Item>
                                <Nav.Link eventKey="businessprofile" onClick={(e) => setKey('businessprofile')}>
                                    <span className="nav-link-text">Business Profile</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="teammembers" onClick={(e) => setKey('teammembers')}>
                                    <span className="nav-link-text">Team Member</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="customdomain" onClick={(e) => setKey('customdomain')}>
                                    <span className="nav-link-text">Custom Domain</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="planbilling" onClick={(e) => setKey('planbilling')}>
                                    <span className="nav-link-text">Plan & Billing</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="changepassword" onClick={(e) => setKey('changepassword')}>
                                    <span className="nav-link-text">Change Password</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body">
                        <Tab.Content>
                            <Tab.Pane eventKey="businessprofile">

                            </Tab.Pane>
                            <Tab.Pane eventKey="teammembers">

                            </Tab.Pane>
                            <Tab.Pane eventKey="customdomain">

                            </Tab.Pane>
                            <Tab.Pane eventKey="planbilling">
                               
                            </Tab.Pane>
                            <Tab.Pane eventKey="changepassword">
                               
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(AccountSettingsIndex);