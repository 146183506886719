import { Archive, Copy, Edit, MoreVertical, Trash, Trash2, Eye } from 'react-feather';
import { IconTableExport, IconCopy, IconEdit, IconTrash, IconFileTypeXls, IconFileTypeCsv } from '@tabler/icons-react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import Swal from 'sweetalert2';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import { useState } from 'react';

//Custom Action Container
const actionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;
    

    const ExportContactsHandler = async(elem, id, type) => {

        const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/export/lists';
        const apiParams = {
            'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
            'id': id,
            'type': type
        }
       
        const bodySend = JSON.stringify(apiParams);
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: bodySend // Sending current page to fetch data accordingly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const result = await response.json();

        if(result?.status){
            window.open(result?.link_download, '_blank');
        }else{
            Swal.fire(
                'Failed!',
                result?.message,
                'danger'
            )
        }
    }

    const handleDeleteContacts = (elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/delete/lists';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                <Dropdown>
                    <Dropdown.Toggle variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret" aria-expanded="false" data-bs-toggle="dropdown">
                        <span className="icon">
                            <span className="feather-icon">
                                <IconTableExport />
                            </span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Header as="h6" className='fs-8'>EXPORT TO</Dropdown.Header>
                        <Dropdown.Item onClick={(elem) => ExportContactsHandler(elem, data?.id, 'xlsx')}>
                            <div className='d-flex gap-2 align-items-center'>
                                <span className="feather-icon dropdown-icon fs-6 me-0">
                                    <IconFileTypeXls />
                                </span>
                                <span className='fs-7'>Export to Excel</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(elem) => ExportContactsHandler(elem, data?.id, 'csv')}>
                            <div className='d-flex gap-2 align-items-center'>
                                <span className="feather-icon dropdown-icon fs-6 me-0">
                                    <IconFileTypeCsv />
                                </span>
                                <span className='fs-7'>Export to CSV</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconEdit />
                        </span>
                    </span>
                </Button>
                <Button variant="flush-dark" onClick={(elem) => handleDeleteContacts(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
            </div>
        </div>
    )
}

const fieldsActionFormater = (data, callbackRefreshTable) => {

    const handleDeleteFields = async(elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/delete/fields';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }
        
    return (<>
     <div className="d-flex align-items-center">
        <div className="d-flex">
                <Button variant="flush-dark" as={(data?.id == 'static_fields') ? Button : Link} to={(data?.id == 'static_fields') ? '' : '/edit-field/' + data?.id} disabled={(data?.id == 'static_fields') ? true : false} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit This Field">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconEdit />
                        </span>
                    </span>
                </Button>
                <Button variant="flush-dark" disabled={(data?.id == 'static_fields') ? true : false} onClick={(elem) => handleDeleteFields(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Remove This Field">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
        </div>
    </div>
    </>)
}

const subscribersActionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;

    const handleDeleteSubscriber = (elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/delete/lists';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconEdit />
                        </span>
                    </span>
                </Button>
                <Button variant="flush-dark" onClick={(elem) => handleDeleteSubscriber(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
            </div>
        </div>
    )
}

const quarantineActionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;
    

    const ExportQuarantineHandler = async(elem, id, type) => {

        const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/export/quarantine';
        const apiParams = {
            'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
            'id': id,
            'type': type
        }
       
        const bodySend = JSON.stringify(apiParams);
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: bodySend // Sending current page to fetch data accordingly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const result = await response.json();

        if(result?.status){
            window.open(result?.link_download, '_blank');
        }else{
            Swal.fire(
                'Failed!',
                result?.message,
                'danger'
            )
        }
    }

    const handleDeleteQuarantine = (elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "All emails in this quarantine will be deleted forever from all your audience lists, are you sure?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/delete/quarantine';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                <Dropdown>
                    <Dropdown.Toggle variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret" aria-expanded="false" data-bs-toggle="dropdown">
                        <span className="icon">
                            <span className="feather-icon">
                                <IconTableExport />
                            </span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Header as="h6" className='fs-8'>EXPORT TO</Dropdown.Header>
                        <Dropdown.Item onClick={(elem) => ExportQuarantineHandler(elem, data?.id, 'xlsx')}>
                            <div className='d-flex gap-2 align-items-center'>
                                <span className="feather-icon dropdown-icon fs-6 me-0">
                                    <IconFileTypeXls />
                                </span>
                                <span className='fs-7'>Export to Excel</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(elem) => ExportQuarantineHandler(elem, data?.id, 'csv')}>
                            <div className='d-flex gap-2 align-items-center'>
                                <span className="feather-icon dropdown-icon fs-6 me-0">
                                    <IconFileTypeCsv />
                                </span>
                                <span className='fs-7'>Export to CSV</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant="flush-dark" onClick={(elem) => handleDeleteQuarantine(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
            </div>
        </div>
    )
}


const quarantineViewActionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;

    const handleDeleteEmailQuarantine = (elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "All emails in this quarantine will be deleted forever from all your audience lists, are you sure?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'quarantine/delete/email';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                <Button variant="flush-dark" onClick={(elem) => handleDeleteEmailQuarantine(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
            </div>
        </div>
    )
}

export const colLists = [
    {
        accessor: "name",
        title: "Name",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/list-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "active_audience",
        title: "Active Audiences",
        sort: true,
    },
    {
        accessor: "open_rate",
        title: "Open Rate",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "click_rate",
        title: "Click Rate",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "created_at",
        title: "Date Create",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];


export const colSubscribers = [
    {
        accessor: "fullname",
        title: "Fullname",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/list-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "email",
        title: "Email",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "date_added",
        title: "Date Added",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: subscribersActionFormater,
    },
];


export const colFields = [
    {
        accessor: "name",
        title: "Name",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/list-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "type",
        title: "Type",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "identifier",
        title: "Identifier",
        elipsis: false,
        sort: true,
        cellFormatter: ( data ) => {
            try {
                var dataJson = JSON.parse(data)

                const titleCopyTooltip = 'Click to copy';

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (
                        <div className='d-flex gap-2'>
                            <span className='d-inline'>{dataJson?.text}</span>
                            <HkTooltip title={titleCopyTooltip}>
                                <a role='button' onClick={(elr) => {
                                     const textToCopy = dataJson?.text;
                                     navigator.clipboard.writeText(textToCopy).then(() => {
                                        
                                     }).catch(err => {
                                       console.error('Failed to copy:', err);
                                     });
                                }} className='text-muted'><IconCopy size={16}/></a>
                            </HkTooltip>
                        </div>
                    )
                }else{
                    return (<></>)
                }
            } catch (e) {
                return (<></>)
            }
        },
    },
    {
        accessor: "last_update",
        title: "Last Updated",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: fieldsActionFormater,
    },
];


export const colQuarantine = [
    {
        accessor: "last_identified",
        title: "Last Identified",
        elipsis: false,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/quarantine/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "total_mail_qurantine",
        title: "Total Email",
        elipsis: false,
        sort: true
    },
    {
        accessor: "total_mail_spam",
        title: "Spam",
        elipsis: false,
        sort: true
    },
    {
        accessor: "total_mail_bounce",
        title: "Bounce / Invalid",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: quarantineActionFormater,
    },
];


export const colQuarantineView = [
    {
        accessor: "email",
        title: "Email",
        elipsis: false,
        sort: true
    },
    {
        accessor: "status",
        title: "Status",
        elipsis: false,
        sort: true
    },
    {
        accessor: "lists",
        title: "Lists",
        elipsis: false,
        sort: false
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: quarantineViewActionFormater,
    },
];