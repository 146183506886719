import React from 'react';
import ReactApexChart from 'react-apexcharts';

const OverviewChart = ({categories, series }) => {

    if(categories && series){
        var options = {

            chart: {
                type: 'bar',
                height: 250,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                foreColor: "#646A71",
                fontFamily: 'DM Sans',
            },
    
            grid: {
                borderColor: '#F4F5F6',
            },
    
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '35%',
                    borderRadius: 5,
                    borderRadiusApplication: "end",
                    borderRadiusWhenStacked: "last",
                },
            },
            xaxis: {
                categories: categories,
    
                labels: {
                    style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                    },
                },
                axisBorder: {
                    show: false,
                },
                title: {
                    style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                    },
                },
                title: {
                    style: {
                        fontSize: '12px',
                        fontFamily: 'inherit',
                    }
                },
            },
            legend: {
                show: true,
                position: 'top',
                fontSize: '15px',
                labels: {
                    colors: '#6f6f6f',
                },
                markers: {
                    width: 8,
                    height: 8,
                    radius: 15
    
                },
                itemMargin: {
                    vertical: 5
                },
            },
    
            colors: ['#007D88', '#25cba1', '#ebf3fe'],
            fill: {
                opacity: 1
            },
            dataLabels: {
                enabled: false,
            }
        };
    
        return <ReactApexChart options={options} series={series} type="bar" height={270} />
    }else{
        return <></>
    }

    
}

export default OverviewChart
