import * as Icons from 'tabler-icons-react';
import * as TablerIcons from '@tabler/icons-react';

import HkBadge from '../../components/@hk-badge/@hk-badge';

export const SidebarMenu = [
    {
        group: '',
        contents: [
            {
                name: 'Dashboard',
                icon: <Icons.Template />,
                path: '/dashboard',
            },
            {
                name: 'Broadcasts',
                icon: <Icons.MailForward />,
                path: '/broadcast',
                subPath: ['/broadcast-view/:id', '/broadcast-editor']
            },
            {
                name: 'Audiences',
                icon: <Icons.Users />,
                path: '/audiences',
                subPath: ['/list-view/:id', '/create-audience/:id']
            },
            {
                name: 'Templates',
                icon: <TablerIcons.IconMailCog />,
                path: '/templates',
                subPath: ['/templates/editor/:id']
            },
            {
                name: 'Forms',
                icon: <TablerIcons.IconForms />,
                path: '/forms',
            },
            {
                name: 'Autoresponders',
                icon: <TablerIcons.IconSettingsAutomation />,
                path: '/autoresponders',
            },
            {
                name: 'Landing Pages',
                icon: <TablerIcons.IconBrowser />,
                path: '/landing-pages',
            },
            {
                name: 'Automations',
                icon: <TablerIcons.IconAutomaticGearbox />,
                path: '/automations',
            },
        ],
    },
    {
        group: 'Others',
        contents: [
            {
                name: 'Email Senders',
                icon: <TablerIcons.IconSend2 />,
                path: '/emailsenders',
            },
            {
                name: 'Integrations',
                icon: <TablerIcons.IconCode />,
                path: '/integrations',
            },
            {
                name: 'Account Settings',
                icon: <TablerIcons.IconUserSearch />,
                path: '/accounts-settings',
            }
        ]
    }

]