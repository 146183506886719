import { Archive, Copy, Edit, MoreVertical, Trash, Trash2, Eye } from 'react-feather';
import { IconTableExport, IconCopy, IconEdit, IconTrash, IconFileTypeXls, IconFileTypeCsv, IconPencil } from '@tabler/icons-react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import Swal from 'sweetalert2';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import { useState } from 'react';

const indexActionFormater = (data, callbackRefreshTable) => {

    const handleDeleteTemplate = (elem, id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "If there is a delivery using this sender it will stop",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'emailsender/delete';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                <Button variant="flush-dark" onClick={(elem) => handleDeleteTemplate(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete Template">
                    <span className="icon">
                        <span className="feather-icon">
                            <IconTrash />
                        </span>
                    </span>
                </Button>
            </div>
        </div>
    )
}


export const colEmailSenderIndex = [
    {
        accessor: "sender_name",
        title: "Sender Name",
        elipsis: false,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                // console.log('dataJsonPrt', dataJson)

                if((typeof dataJson === 'object' && dataJson !== null)){

                    return (<>
                        <div className='live-editing-td'>
                            <b>{dataJson?.text}</b>
                            <a href='#' role='button'>
                                <IconPencil size={17}/>
                            </a>
                        </div>
                        <p style={{margin: '0px'}}>{dataJson?.otherprop?.senders_email}</p>
                    </>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "reply_to",
        title: "Reply-To",
        elipsis: false,
        sort: true
    },
    {
        accessor: "verification",
        title: "Verification",
        elipsis: false,
        sort: false
    },
    {
        accessor: "domain_auth",
        title: "Domain Authentication",
        elipsis: false,
        sort: false
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: indexActionFormater,
    },
];