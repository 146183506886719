import React, { useEffect, useRef, useState, createRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Accordion, Button, OverlayTrigger, Tooltip, Alert, Card } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import { SketchPicker } from 'react-color';
import Select from 'react-select';

import lexTheme from "./lexTheme/index";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
// import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoLinkNode, LinkNode } from "@lexical/link";

import ContentLoader, { Facebook } from 'react-content-loader'

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import ToolbarPlugin from "./lexPlugin/ToolbarPlugin";
import AutoLinkPlugin from "./lexPlugin/AutoLinkPlugin"
import ListMaxIndentLevelPlugin from "./lexPlugin/ListMaxIndentLevelPlugin"
import CodeHighlightPlugin from "./lexPlugin/CodeHighlightPlugin";
import TreeViewPlugin from "./lexPlugin/TreeViewPlugin";
import OnChangePlugin from '@lexical/react/LexicalOnChangePlugin'
import {$generateHtmlFromNodes} from '@lexical/html';
import {$generateNodesFromDOM} from '@lexical/html';

import {$createParagraphNode, $createTextNode, $getRoot, $insertNodes} from 'lexical';

import { Player } from '@lordicon/react';
import { IconAlertCircle, IconCheck, IconPalette, IconPhotoUp, IconTrash, IconMenu2, IconPencil } from '@tabler/icons-react';
import { node } from 'prop-types';
const SMILEJson = require('../Index/iconjson/smile.json');

const FormsEditorIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    // const { id } = useParams();

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    const dispatch = useDispatch();
    const history = useHistory();

    const [isVisible, setIsVisible] = useState({});
    const contentRefs = useRef([]);

    const [formInfoData, setFormInfoData] = useState({})
    const [sealListItems, setSealListItems] = useState([])
    const [emailSenderLists, setEmailSenderLists] = useState([])

    const [fieldConfigurations, setFieldConfigurations] = useState([])

    const [useTriggerWait, setUseTriggerWait] = useState(false)

    const [showTitleOnForm, setShowTitleOnForm] = useState(false)
    const [showDescOnForm, setShowDescOnForm] = useState(false)
    const [embedYoutubeOnForm, setEmbedYoutubeOnForm] = useState(false)

    const [showFooterBranding, setShowFooterBranding] = useState(false)

    const [doneLoadingData, setDoneLoadingData] = useState(false);

    const [isFirstRenderEmailConfirm, setIsFirstRenderEmailConfirm] = useState(false);
    const [isFirstRenderAfterEmailConfirm, setIsFirstRenderAfterEmailConfirm] = useState(false);

    const [viewAsMobile, setViewAsMobile] = useState(false)

    const [refreshContent, setRefreshContent] = useState(0)

    const [reloadKey, setReloadKey] = useState(0);
    const [customFieldsAvailable, setCustomFieldsAvailable] = useState([])
    const [customFieldsAvailableObjKeys, setCustomFieldsAvailableObjKeys] = useState([])

    const [customFieldNewKeyValue, setCustomFieldNewKeyValue] = useState('')

    const reloadIframe = () => {
      // Increment the key to force iframe to reload
      setReloadKey(prevKey => prevKey + 1);
    };

    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
        'id': id
    }

    if (contentRefs.current.length === 0) {
        contentRefs.current = Array(5).fill().map((_, i) => contentRefs.current[i] || createRef());
    }
    
    const toggleVisibility = (index) => {
        // Toggle visibility for the specified index

        var stateIsVisible = !isVisible[index]
        if(typeof stateIsVisible == 'undefined'){
            stateIsVisible = false;
        }

        console.log('stateIsVisible ('+index+')',stateIsVisible)

        setIsVisible({
          ...isVisible,
          [index]: stateIsVisible
        });
        
    };

    useEffect(() => {
        toggleVisibility(0)
    },[])
    

    const [listStateColorPicker, setListStateColorPicker] = useState({
        'theme_bg_color': {
            opened: false,
            prevValue: '#56340C',
            value: '#56340C'
        },
        'theme_txt_color': {
            opened: false,
            prevValue: '#ffffff',
            value: '#ffffff'
        },
        'theme_btn_submit_bg_color': {
            opened: false,
            prevValue: '#f5c144',
            value: '#f5c144'
        },
        'theme_btn_submit_txt_color': {
            opened: false,
            prevValue: '#222529',
            value: '#222529'
        }
    })

    const [previewIframeUrl, setPreviewIframeUrl] = useState('')

    const [affectedLists, setAffectedLists] = useState([]); 
    const [affectedListsSelected, setAffectedListsSelected] = useState([]); 


    const lexOnError = (error) => {

    }
    const lexInitialConfig = {
        namespace: 'lx_email_content_editor',
        lexTheme,
        lexOnError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };

    const [dblEmailConfirmationContent, setDblEmailConfirmationContent] = useState('')
    const [dblAfterConfirmationContent, setDblAfterConfirmationContent] = useState('')

    function EmailConfirmationOnChange({ onChange }) {
        const [editor] = useLexicalComposerContext();
  
        useEffect(() => {
          return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                setDblEmailConfirmationContent(htmlString)
            });
          });
        }, [editor, onChange]);

        useEffect(() => {

            // console.log('dblEmailConfirmationContent', dblEmailConfirmationContent)
            // console.log('isFirstRenderEmailConfirm', isFirstRenderEmailConfirm)

            if(dblEmailConfirmationContent != '') {
                if(!isFirstRenderEmailConfirm != '') return;
            
                setIsFirstRenderEmailConfirm(false);
    
                editor.update(() => {
                    const parser = new DOMParser();
                    const dom = parser.parseFromString(dblEmailConfirmationContent, "text/html");
                    const nodes = $generateNodesFromDOM(editor, dom);
                    $insertNodes(nodes);
                });
            }else{
                return
            }
           
        }, [refreshContent])

        return null;
    }

    function AfterConfirmationOnChange({ onChange }) {
        const [editor] = useLexicalComposerContext();
        useEffect(() => {
          return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                setDblAfterConfirmationContent(htmlString)
            });
          });
        }, [editor, onChange]);

        useEffect(() => {

            if(dblAfterConfirmationContent != '') {
                if(!isFirstRenderAfterEmailConfirm != '') return;
            
                setIsFirstRenderAfterEmailConfirm(false);
    
                editor.update(() => {
                    const parser = new DOMParser();
                    const dom = parser.parseFromString(dblAfterConfirmationContent, "text/html");
                    const nodes = $generateNodesFromDOM(editor, dom);
                    $insertNodes(nodes);
                });
            }else{
                return
            }
           
        }, [refreshContent])

        return null;
    }

    function LexPlaceholder() {
        return <div className="editor-placeholder">Enter some rich text...</div>;
      }

    const updateParamProperty = (paramKey, propertyToUpdate, newValue) => {
        setListStateColorPicker(prevState => ({
            ...prevState,
            [paramKey]: {
                ...prevState[paramKey],
                [propertyToUpdate]: newValue
            }
        }));
    };

    const playerRef = useRef(null);
    const [refreshId, setRefreshId] = useState('');

    const [previewSelected, setPreviewSelected] = useState('desktop')
    const [useThisFormType, setUseThisFormType] = useState('embedded')
    const [themeLayoutSelected, setThemeLayoutSelected] = useState('default')
    const [accordionDesignActiveKey, setAccordionDesignActiveKey] = useState('behavior')
    const [accordionFormConfigActiveKey, setAccordionFormConfigActiveKey] = useState('optinmethod')

    const [accordionSidePanel, setAccordionSidePanel] = useState(['form_name', 'form_type', 'form_design', 'form_config', 'field_configuration'])

    const handleAccordionDesignActiveChanged = (key) => {
        setAccordionDesignActiveKey(key === accordionDesignActiveKey ? null : key)
    };
        

    const [optInMethod, setOptInMethod] = useState('doubleoptin')
    const [stateOpenEmailConfirmation, setStateOpenEmailConfirmation] = useState('emailconfirmation')

    const [imageLogoInvalidFeedback, setImageLogoInvalidFeedback] = useState('')

    const formRef = useRef();

   

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Form Builder',
            action_right: {
                title: 'Save Changed',
                classlists: 'btn btn-primary btn-md fs-6',
                icons: '',
                onclick: ((elem) => {
                    executeSubmitForm()
                })
            }
        }));
    }, [refreshId])

    useEffect(() => {
        toggleCollapsedNav(true);
        playerRef.current?.playFromBeginning();
        getInfo(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInfo = async(id) => {

        console.log('GetInfoId', id)

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'form/info';
        
            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){
                setFormInfoData(result?.info)
                setUseThisFormType(result?.info?.appereances?.forms_type)
                setThemeLayoutSelected(result?.info?.appereances?.forms_design?.themes?.mode)

                updateParamProperty('theme_bg_color', 'value', result?.info?.appereances?.forms_design?.themes?.form_content?.bg_color)
                updateParamProperty('theme_txt_color', 'value', result?.info?.appereances?.forms_design?.themes?.form_content?.txt_color)

                updateParamProperty('theme_btn_submit_bg_color', 'value', result?.info?.appereances?.forms_design?.themes?.submit_button?.bg_color)
                updateParamProperty('theme_btn_submit_txt_color', 'value', result?.info?.appereances?.forms_design?.themes?.submit_button?.txt_color)
            
                setSealListItems(result?.info?.seal_items)
                setAffectedLists(result?.info?.contacts)

                const contactsSelected = result?.info?.contacts?.filter(item => item.defaultSelected === true)
                setAffectedListsSelected(contactsSelected)

                console.log('contactsSelected', contactsSelected)

                setEmailSenderLists(result?.info?.senders)
                setFieldConfigurations(result?.info?.appereances?.fields_configuration)

                setUseTriggerWait(result?.info?.appereances?.forms_design?.triggers?.using_wait)

                setShowTitleOnForm((result?.info?.appereances?.forms_design?.content?.form_title?.is_active) ? true : false)
                setShowDescOnForm((result?.info?.appereances?.forms_design?.content?.form_desc?.is_active) ? true : false)
                setEmbedYoutubeOnForm((result?.info?.appereances?.forms_design?.content?.form_youtube_embed?.is_active) ? true : false)
                setShowFooterBranding((result?.info?.appereances?.forms_design?.others?.footer_branding?.is_active) ? true : false)

                setOptInMethod(result?.info?.appereances?.forms_config?.opt_in_method?.type)

                setDblEmailConfirmationContent(result?.info?.appereances?.forms_config?.opt_in_method?.email_confirmation?.email_contents)
                setDblAfterConfirmationContent(result?.info?.appereances?.forms_config?.opt_in_method?.after_confirmation?.email_contents)

                setRefreshContent(new Date().getTime())
                setIsFirstRenderEmailConfirm(true)
                setIsFirstRenderAfterEmailConfirm(true)

                setDoneLoadingData(true)
                setPreviewIframeUrl(result?.info?.preview_url)

                const customFieldsAvailableObjs = result?.info?.custom_fields
                const customFieldsAvailableFromArray = [];
                for (const key in customFieldsAvailableObjs) {
                    if (customFieldsAvailableObjs.hasOwnProperty(key)) {
                        customFieldsAvailableFromArray.push(customFieldsAvailableObjs[key]);
                    }
                }
                // const customFieldsAvailableFromObj = Object.entries(result?.info?.custom_fields);

                // console.log('CustomFieldAvailable', customFieldsAvailableFromArray)

                setCustomFieldsAvailable(customFieldsAvailableFromArray)
                setCustomFieldsAvailableObjKeys(customFieldsAvailableObjs)
            }else{
                setFormInfoData({})
            }
        }
        
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();
        executeSubmitForm()
    }

    const executeSubmitForm = () => {
        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            const keyRegex = /(\w+)\[(\{\{[\w\s]+\}\}|\w+)\]/; // Updated regular expression to match keys with square brackets and handlebars notation
            const match = key.match(keyRegex);
        
            if (match) {
                const objKey = match[1]; // Extract the key before square brackets
                const subKey = match[2]; // Extract the key inside square brackets or handlebars notation
        
                if (!data[objKey]) {
                    data[objKey] = {};
                }
        
                if (!Array.isArray(data[objKey][subKey])) {
                    data[objKey][subKey] = [];
                }
        
                data[objKey][subKey].push(value);
            } else {
                if (!data[key]) {
                    data[key] = value;
                    return;
                }
                if (!Array.isArray(data[key])) {
                    data[key] = [data[key]];
                }
                data[key].push(value);
            }
        });

        // console.log(data)

        const authValue = apiParams?.auth
        const servicesValue = apiParams?.services

        if (!data["auth"]) {
            data["auth"] = authValue;
        } else {
            if (!Array.isArray(data["auth"])) {
                data["auth"] = [data["auth"]];
            }
            data["auth"].push(authValue);
        }
        
        if (!data["services"]) {
            data["services"] = servicesValue;
        } else {
            if (!Array.isArray(data["services"])) {
                data["services"] = [data["services"]];
            }
            data["services"].push(servicesValue);
        }

        // console.log(data);

        // console.log('blocksContentSettings', blocksContentSettings)

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'form/save';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){
                reloadIframe()
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    const CustomToggle = ({ children, eventKey, deleteIsEnabled, onDeleteEvent }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
          console.log('totally custom!'),
        );

        return (

            <div className='header-on-minify'>
                <div className='hom-left'>
                    <a role='button' className='btn btn-light shadow-none handle'>
                        <IconMenu2 size={18}/>
                        <span className="sr-only">Handle</span>
                    </a>
                    <b>{children}</b>
                </div>
                <div className='hom-right'>
                    <a role='button' data-allowtoogle={'1'} onClick={(el) => {
                        decoratedOnClick()
                    }}>
                        <IconPencil size={18}/>
                    </a>
                    <a role='button' className={(!deleteIsEnabled) ? 'disabled' : ''} onClick={onDeleteEvent}>
                        <IconTrash size={18}/>
                    </a>
                </div>
            </div>

          
        );
    }

    return (
        <>{(!doneLoadingData) ? <></> :  <div className='d-flex w-100 h-100'>
        <div className='row p-3 w-100'>
            <div className='col-lg-5'>
                <Form ref={formRef} onSubmit={handleSubmitAddManual}>
                    <Accordion className='accordion-soft accordion-card' defaultActiveKey={accordionSidePanel} onSelect={(key) => {
                        // setAccordionSidePanel(key)
                    }} alwaysOpen>
                        <Accordion.Item className='mb-3' eventKey="form_name">
                            <Accordion.Header>Form Name</Accordion.Header>
                            <Accordion.Body className='content-self-accordion'>
                                <Form.Control type='hidden' defaultValue={id} name='id' className='id' id='id'/>
                                <Form.Control defaultValue={formInfoData?.appereances?.forms_name} name='form_name' type='text' id='form_name' className='form_name' placeholder="Your form name" aria-label='unsubscribe-links-snippets'/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mb-3' eventKey="form_type">
                            <Accordion.Header>Form Type</Accordion.Header>
                            <Accordion.Body className='content-self-accordion'>
                                <Form.Control type="hidden" name="layout_form_type" defaultValue={useThisFormType} className="layout_form_type" id="layout_form_type"/>
                                <Tab.Container activeKey={useThisFormType}>
                                    <Nav variant="pills" className="nav-light nav-justified nav-segmented-tabs nav mx-0 service-type tab-with-iconand-label mt-3">
                                        <Nav.Item className='px-1'>
                                            <Nav.Link className='px-1' eventKey="embedded" onClick={(e) => setUseThisFormType('embedded')}>
                                                <span className="nav-link-text">
                                                    <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580054581-embedded.png" className="mb-2" width="60" alt="Form"/>
                                                    <b>Embedded</b>
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='px-1'>
                                            <Nav.Link className='px-1' eventKey="popup" onClick={(e) => setUseThisFormType('popup')}>
                                                <span className="nav-link-text">
                                                    <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580054575-pop-up.png" className="mb-2" width="60" alt="Form"/>
                                                    <b>Pop-Up</b>
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='px-1'>
                                            <Nav.Link className='px-1' eventKey="slidein" onClick={(e) => setUseThisFormType('slidein')}>
                                                <span className="nav-link-text">
                                                    <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580054578-slide-in.png" className="mb-2" width="60" alt="Form"/>
                                                    <b>Slide-In</b>
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='px-1'>
                                            <Nav.Link className='px-1' eventKey="fixedbar" onClick={(e) => setUseThisFormType('fixedbar')}>
                                                <span className="nav-link-text">
                                                    <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580054571-fixedbar.png" className="mb-2" width="60" alt="Form"/>
                                                    <b>Fixed Bar</b>
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mb-3' eventKey="form_design">
                            <Accordion.Header>Form Design</Accordion.Header>
                            <Accordion.Body className='content-self-accordion'>
                                <Accordion className='accordion-soft' defaultActiveKey={accordionDesignActiveKey} onSelect={handleAccordionDesignActiveChanged}>
                                    <Accordion.Item eventKey="behavior">
                                        <Accordion.Header>Behavior</Accordion.Header>
                                        <Accordion.Body>
                                            <label className="text-dark d-flex mb-0">Triggers</label>
                                            <small>Choose when you want to show the pop-up.</small>
                                            <div className="d-flex align-items-center gap-2 mt-3">
                                                <div className="form-check form-check-primary">
                                                    <Form.Check role='button' type='checkbox' defaultChecked={false} checked={useTriggerWait} onChange={(event) => { setUseTriggerWait(event.target.checked) }} name='use-triggers-wait' className='use-triggers-wait' id='use-triggers-wait' defaultValue={'1'}/>
                                                    <label role='button' className="text-dark mb-0" htmlFor="use-triggers-wait">Wait</label>
                                                </div>
                                                <Form.Control type='text' name="delay-triggers-value" id="delay-triggers-value" className="delay-triggers-value form-control" defaultValue={formInfoData?.appereances?.forms_design?.triggers?.interval_wait} placeholder="0" style={{width: '45px', textAlign: 'center', fontSize: '14px'}}/>
                                                <label className="text-dark mb-0" htmlFor="use-triggers-wait">seconds before showing the pop-up</label>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="theme">
                                        <Accordion.Header>Theme</Accordion.Header>
                                        <Accordion.Body>
                                            <Tab.Container activeKey={themeLayoutSelected}>
                                                <Form.Control type='hidden' name='themes-mode' value={themeLayoutSelected} id='themes-mode' className='themes-mode'/>
                                                <Nav variant="pills" className="nav-light nav-justified nav-segmented-tabs nav mx-0 service-type tab-with-iconand-label mt-3">
                                                    <Nav.Item className='px-1'>
                                                        <Nav.Link className='px-1 themeformchooser bg-warning-soft border-warning-dark' eventKey="default" onClick={(e) => setThemeLayoutSelected('default')}>
                                                            <span className="nav-link-text">
                                                                <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580072622-colored.png" className="mb-2" width="60" alt="Form"/>
                                                                <b>Default</b>
                                                                <small>Colored Background</small>
                                                            </span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='px-1'>
                                                        <Nav.Link className='px-1 themeformchooser bg-warning-soft border-warning-dark' eventKey="light" onClick={(e) => setThemeLayoutSelected('light')}>
                                                            <span className="nav-link-text">
                                                                <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580072618-light.png" className="mb-2" width="60" alt="Form"/>
                                                                <b>Light</b>
                                                                <small>Transparent Background</small>
                                                            </span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='px-1'>
                                                        <Nav.Link className='px-1 themeformchooser bg-warning-soft border-warning-dark' eventKey="dark" onClick={(e) => setThemeLayoutSelected('dark')}>
                                                            <span className="nav-link-text">
                                                                <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/EmailAja/Public/580072630-dark.png" className="mb-2" width="60" alt="Form"/>
                                                                <b>Dark</b>
                                                                <small>Transparent background</small>
                                                            </span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <div className='form-theme-content-settings mt-4 accordion-sub-conf p-2'>
                                                    <h5 className='fs-12 text-muted mb-1'>Form Content</h5>
                                                    <div className='form-row bar-orientations parent-content-color-chooser frame-select-directions-chooser'>
                                                        <div className='content-color-chooser'>
                                                            <label className='mb-0 fs-13 text-dark'>Background Color</label>
                                                            <div className='frame-monolith-colorpicker'>
                                                                <div className='edit-popover-color-picker'>
                                                                    <Form.Control type='hidden' name='theme_bg_color' className='theme_bg_color' id='theme_bg_color' value={listStateColorPicker?.theme_bg_color?.value}/>
                                                                    <div className='pickr'>
                                                                        <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip">Click to choose color.</Tooltip>}>
                                                                            <Button role='button' onClick={(el) => {
                                                                                updateParamProperty('theme_bg_color', 'opened', !listStateColorPicker?.theme_bg_color?.opened)
                                                                            }} className='pcr-button' aria-label={'toggle color picker dialog'} style={{'background': listStateColorPicker?.theme_bg_color?.value}}></Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <IconPalette fontSize={15}/>
                                                                </div>
                                                                <div className='monolith-cp-frame-popup-parent'>
                                                                    {(listStateColorPicker?.theme_bg_color?.opened) ?  <div className='monolith-cp-frame-popup-child'>
                                                                        <SketchPicker color={listStateColorPicker?.theme_bg_color?.value} onChangeComplete={(color) => {
                                                                            // this.setState({ background: color.hex });
                                                                            // setColorState(color.hex)
                                                                            updateParamProperty('theme_bg_color', 'value', color?.hex)
                                                                        }}/>
                                                                        <div className='d-flex gap-2 mt-2'>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_bg_color', 'prevValue', listStateColorPicker?.theme_bg_color?.value)
                                                                                updateParamProperty('theme_bg_color', 'opened', false)
                                                                            }} className='btn btn-primary btn btn-primary flex-1 btn-sm'>Ok</Button>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_bg_color', 'value', listStateColorPicker?.theme_bg_color?.prevValue)
                                                                                updateParamProperty('theme_bg_color', 'opened', false)
                                                                            }} className='btn btn-secondary btn btn-light flex-1 btn-sm'>Cancel</Button>
                                                                        </div>
                                                                    </div> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='content-color-chooser'>
                                                            <label className='mb-0 fs-13 text-dark'>Text Color</label>
                                                            <div className='frame-monolith-colorpicker'>
                                                                <div className='edit-popover-color-picker'>
                                                                    <Form.Control type='hidden' name='theme_txt_color' className='theme_txt_color' id='theme_txt_color' value={listStateColorPicker?.theme_txt_color?.value}/>
                                                                    <div className='pickr'>
                                                                        <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip">Click to choose color.</Tooltip>}>
                                                                            <Button role='button' onClick={(el) => {
                                                                                updateParamProperty('theme_txt_color', 'opened', !listStateColorPicker?.theme_txt_color?.opened)
                                                                            }} className='pcr-button' aria-label={'toggle color picker dialog'} style={{'background': listStateColorPicker?.theme_txt_color?.value}}></Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <IconPalette fontSize={15}/>
                                                                </div>
                                                                <div className='monolith-cp-frame-popup-parent'>
                                                                    {(listStateColorPicker?.theme_txt_color?.opened) ?  <div className='monolith-cp-frame-popup-child'>
                                                                        <SketchPicker color={listStateColorPicker?.theme_txt_color?.value} onChangeComplete={(color) => {
                                                                            // this.setState({ background: color.hex });
                                                                            // setColorState(color.hex)
                                                                            updateParamProperty('theme_txt_color', 'value', color?.hex)
                                                                        }}/>
                                                                        <div className='d-flex gap-2 mt-2'>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_txt_color', 'prevValue', listStateColorPicker?.theme_txt_color?.value)
                                                                                updateParamProperty('theme_txt_color', 'opened', false)
                                                                            }} className='btn btn-primary btn btn-primary flex-1 btn-sm'>Ok</Button>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_txt_color', 'value', listStateColorPicker?.theme_txt_color?.prevValue)
                                                                                updateParamProperty('theme_txt_color', 'opened', false)
                                                                            }} className='btn btn-secondary btn btn-light flex-1 btn-sm'>Cancel</Button>
                                                                        </div>
                                                                    </div> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='soft-divider normalizep2'/>
                                                <div className='form-theme-content-settings accordion-sub-conf p-2'>
                                                    <h5 className='fs-12 text-muted mb-1'>Submit Button</h5>
                                                    <div className='form-row bar-orientations parent-content-color-chooser frame-select-directions-chooser'>
                                                        <div className='content-color-chooser'>
                                                            <label className='mb-0 fs-13 text-dark'>Background Color</label>
                                                            <div className='frame-monolith-colorpicker'>
                                                                <div className='edit-popover-color-picker'>
                                                                    <Form.Control type='hidden' name='theme_btn_submit_bg_color' className='theme_btn_submit_bg_color' id='theme_btn_submit_bg_color' value={listStateColorPicker?.theme_btn_submit_bg_color?.value}/>
                                                                    <div className='pickr'>
                                                                        <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip">Click to choose color.</Tooltip>}>
                                                                            <Button role='button' onClick={(el) => {
                                                                                updateParamProperty('theme_btn_submit_bg_color', 'opened', !listStateColorPicker?.theme_btn_submit_bg_color?.opened)
                                                                            }} className='pcr-button' aria-label={'toggle color picker dialog'} style={{'background': listStateColorPicker?.theme_btn_submit_bg_color?.value}}></Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <IconPalette fontSize={15}/>
                                                                </div>
                                                                <div className='monolith-cp-frame-popup-parent'>
                                                                    {(listStateColorPicker?.theme_btn_submit_bg_color?.opened) ?  <div className='monolith-cp-frame-popup-child'>
                                                                        <SketchPicker color={listStateColorPicker?.theme_btn_submit_bg_color?.value} onChangeComplete={(color) => {
                                                                            // this.setState({ background: color.hex });
                                                                            // setColorState(color.hex)
                                                                            updateParamProperty('theme_btn_submit_bg_color', 'value', color?.hex)
                                                                        }}/>
                                                                        <div className='d-flex gap-2 mt-2'>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_btn_submit_bg_color', 'prevValue', listStateColorPicker?.theme_btn_submit_bg_color?.value)
                                                                                updateParamProperty('theme_btn_submit_bg_color', 'opened', false)
                                                                            }} className='btn btn-primary btn btn-primary flex-1 btn-sm'>Ok</Button>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_btn_submit_bg_color', 'value', listStateColorPicker?.theme_btn_submit_bg_color?.prevValue)
                                                                                updateParamProperty('theme_btn_submit_bg_color', 'opened', false)
                                                                            }} className='btn btn-secondary btn btn-light flex-1 btn-sm'>Cancel</Button>
                                                                        </div>
                                                                    </div> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='content-color-chooser'>
                                                            <label className='mb-0 fs-13 text-dark'>Text Color</label>
                                                            <div className='frame-monolith-colorpicker'>
                                                                <div className='edit-popover-color-picker'>
                                                                    <Form.Control type='hidden' name='theme_btn_submit_txt_color' className='theme_btn_submit_txt_color' id='theme_btn_submit_txt_color' value={listStateColorPicker?.theme_btn_submit_txt_color?.value}/>
                                                                    <div className='pickr'>
                                                                        <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip">Click to choose color.</Tooltip>}>
                                                                            <Button role='button' onClick={(el) => {
                                                                                updateParamProperty('theme_btn_submit_txt_color', 'opened', !listStateColorPicker?.theme_btn_submit_txt_color?.opened)
                                                                            }} className='pcr-button' aria-label={'toggle color picker dialog'} style={{'background': listStateColorPicker?.theme_btn_submit_txt_color?.value}}></Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <IconPalette fontSize={15}/>
                                                                </div>
                                                                <div className='monolith-cp-frame-popup-parent'>
                                                                    {(listStateColorPicker?.theme_btn_submit_txt_color?.opened) ?  <div className='monolith-cp-frame-popup-child'>
                                                                        <SketchPicker color={listStateColorPicker?.theme_btn_submit_txt_color?.value} onChangeComplete={(color) => {
                                                                            // this.setState({ background: color.hex });
                                                                            // setColorState(color.hex)
                                                                            updateParamProperty('theme_btn_submit_txt_color', 'value', color?.hex)
                                                                        }}/>
                                                                        <div className='d-flex gap-2 mt-2'>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_btn_submit_txt_color', 'prevValue', listStateColorPicker?.theme_btn_submit_txt_color?.value)
                                                                                updateParamProperty('theme_btn_submit_txt_color', 'opened', false)
                                                                            }} className='btn btn-primary btn btn-primary flex-1 btn-sm'>Ok</Button>
                                                                            <Button onClick={(e) => {
                                                                                updateParamProperty('theme_btn_submit_txt_color', 'value', listStateColorPicker?.theme_btn_submit_txt_color?.prevValue)
                                                                                updateParamProperty('theme_btn_submit_txt_color', 'opened', false)
                                                                            }} className='btn btn-secondary btn btn-light flex-1 btn-sm'>Cancel</Button>
                                                                        </div>
                                                                    </div> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="content">
                                        <Accordion.Header>Content</Accordion.Header>
                                        <Accordion.Body className='accordion-sub-conf'>
                                            <div className='form-frame mb-2'>
                                                <label className="form-label fs-14 text-dark" htmlFor="form-logo">Image / Logo</label>
                                                <img className="logo_preview" src="" alt="Logo Preview"></img>
                                                <div className='d-flex gap-3'>
                                                    <button type="button" role='button' className='btn btn-primary fw-bolder btn-label btn-sm add-values-btn mb-3 d-flex gap-0 d-flex gap-2'>
                                                        <IconPhotoUp fontSize={16}/>
                                                        <span>Select an image</span>
                                                    </button>
                                                    <button type="button" role='button' className='btn btn-light fw-bolder text-dark btn-label btn-sm add-values-btn mb-3 d-flex gap-0 d-flex gap-2'>
                                                        <IconTrash fontSize={16}/>
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>{imageLogoInvalidFeedback}</div>
                                            </div>

                                           
                                            <div className='form-frame mb-3'>
                                                <div className='d-flex gap-1'>
                                                    <div className='form-check form-check-warning'>
                                                        <Form.Check role='button' type="checkbox" onChange={(event) => { setShowTitleOnForm(event.target.checked) }} className="fs-6 mb-0 d-flex justify-content-center show_title_on_form" defaultChecked={showTitleOnForm} name='show_title_on_form' id='show_title_on_form' defaultValue={'1'}/>
                                                    </div>
                                                    <label role='button' className='text-dark'>Do you want to show title on top of your form?</label>
                                                </div>
                                                <Form.Control type='text' name='form-title' minLength={5} maxLength={30} id='form-title' className='form-title' defaultValue={formInfoData?.appereances?.forms_design?.content?.form_title?.value} placeholder='Enter your form title'/>
                                                </div>
                                                <div className='form-frame mb-3'>
                                                    <div className='d-flex gap-1'>
                                                        <div className='form-check form-check-warning'>
                                                            <Form.Check role='button' type="checkbox" onChange={(event) => { setShowDescOnForm(event.target.checked) }} className="fs-6 mb-0 d-flex justify-content-center show_desc_on_form" defaultChecked={showDescOnForm} name='show_desc_on_form' id='show_desc_on_form' defaultValue={'1'}/>
                                                        </div>
                                                        <label role='button' className='text-dark'>Do you want to show a description below the title?</label>
                                                    </div>
                                                    <Form.Control type='text' name='form-tagline' minLength={5} maxLength={60} id='form-tagline' className='form-tagline' defaultValue={formInfoData?.appereances?.forms_design?.content?.form_desc?.value} placeholder='Enter your form description'/>
                                                </div>
                                                <div className='form-frame mb-3'>
                                                    <div className='d-flex gap-1'>
                                                        <div className='form-check form-check-warning'>
                                                            <Form.Check role='button' type="checkbox" onChange={(event) => { setEmbedYoutubeOnForm(event.target.checked) }} className="fs-6 mb-0 d-flex justify-content-center embed_youtube_on_form" defaultChecked={embedYoutubeOnForm} name='embed_youtube_on_form' id='embed_youtube_on_form' defaultValue={'1'}/>
                                                        </div>
                                                        <label role='button' className='text-dark'>Do you want to embed a YouTube video?</label>
                                                    </div>
                                                    <Form.Control type='text' name='youtube-url-embed' minLength={5} maxLength={60} id='youtube-url-embed' className='youtube-url-embed' defaultValue={formInfoData?.appereances?.forms_design?.content?.form_youtube_embed?.value} placeholder='Enter your YouTube URL video'/>
                                                </div>
                                                <div className='form-frame mb-3'>
                                                    <div className='d-flex gap-1'>
                                                        <label className='text-dark'>Submit Button Text</label>
                                                    </div>
                                                    <Form.Control type='text' name='form-submitcustomtext' minLength={5} maxLength={60} id='form-submitcustomtext' className='form-submitcustomtext' defaultValue={formInfoData?.appereances?.forms_design?.content?.submit_button_text} placeholder='Submit Now'/>
                                                </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="others">
                                        <Accordion.Header>Others</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='mb-3 d-flex gap-3'>
                                                <div className='form-frame flex-1'>
                                                    <label htmlFor='badge-seals-1' className='text-dark'>Badge Seal 1 <i className='text-muted'>(Optional)</i></label>
                                                    <Form.Select role='button' defaultValue={formInfoData?.appereances?.forms_design?.others?.badge_seal?.seal_1} name='badge-seals-1' id='badge-seals-1' className='badge-seals-1'>
                                                        {sealListItems.map((sealItems, index) => {
                                                            return (<option value={sealItems?.value}>{sealItems?.title}</option>)
                                                        })}
                                                    </Form.Select>
                                                </div>
                                                <div className='form-frame flex-1'>
                                                    <label htmlFor='badge-seals-2' className='text-dark'>Badge Seal 2 <i className='text-muted'>(Optional)</i></label>
                                                    <Form.Select role='button' defaultValue={formInfoData?.appereances?.forms_design?.others?.badge_seal?.seal_2} name='badge-seals-2' id='badge-seals-2' className='badge-seals-2'>
                                                        {sealListItems.map((sealItems, index) => {
                                                            return (<option value={sealItems?.value}>{sealItems?.title}</option>)
                                                        })}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className='form-frame mb-3'>
                                                <label className='text-dark' htmlFor='favicon-url'>Favicon URL</label>
                                                <Form.Control name='favicon-url' id='favicon-url' className='favicon-url' defaultValue={formInfoData?.appereances?.forms_design?.others?.favicon_url} placeholder='Enter your Favicon URL'/>
                                            </div>
                                            <div className='form-frame mb-3'>
                                                <div className='d-flex gap-1'>
                                                    <div className='form-check form-check-warning'>
                                                        <Form.Check role='button' type="checkbox" onChange={(event) => { setShowFooterBranding(event.target.checked) }} className="fs-6 mb-0 d-flex justify-content-center show_footer_branding" defaultChecked={showFooterBranding} name='show_footer_branding' id='show_footer_branding' defaultValue={'1'}/>
                                                    </div>
                                                    <label role='button' className='text-dark' htmlFor='show_footer_branding'>Show footer branding</label>
                                                </div>
                                                <Form.Control type='text' name='footer-branding-text' minLength={5} maxLength={60} id='footer-branding-text' defaultValue={formInfoData?.appereances?.forms_design?.others?.footer_branding?.value} className='footer-branding-text' placeholder='Enter your Footer Branding'/>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mb-3' eventKey="form_config">
                            <Accordion.Header>Form Config</Accordion.Header>
                            <Accordion.Body className='content-self-accordion'>
                                <Accordion className='accordion-soft' defaultActiveKey={accordionFormConfigActiveKey}>
                                    <Accordion.Item eventKey="audiences">
                                        <Accordion.Header>Audience List</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='form-frame'>
                                                <label className='text-dark'>Submitted data will be stored in the following list</label>
                                                <Select
                                                    isMulti
                                                    defaultValue={affectedListsSelected}
                                                    name="affected_lists"
                                                    options={affectedLists}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="optinmethod">
                                        <Accordion.Header>Opt-in Method</Accordion.Header>
                                        <Accordion.Body className='p-0'>
                                            <Tab.Container activeKey={optInMethod}>
                                                <Nav variant="tab" className="nav-light nav-tabs-custom nav-custom-slight nav-custom-headerof-accordion nav-justified nav-segmented-tabs nav mx-0 service-type tab-with-iconand-label mt-0">
                                                    <Nav.Item className='px-0'>
                                                        <Nav.Link className='px-1 no-radius no-border-left no-border-top no-border-right' eventKey="doubleoptin" onClick={(e) => setOptInMethod('doubleoptin')}>
                                                            <div className="nav-link-text d-flex gap-2 justify-content-center align-items-center">
                                                                <Form.Check type="radio" onChange={(elem) => {}} className="type-of-opt" checked={(optInMethod == 'doubleoptin') ? true : false} name='type-of-opt' id='type-of-opt-double' value={'1'}/>
                                                                <span htmlFor={'type-of-opt-double'}>Double Opt-in</span>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='px-0'>
                                                        <Nav.Link className='px-1 no-radius no-border-left no-border-top no-border-right' eventKey="singleoptin" onClick={(e) => {
                                                                setOptInMethod('singleoptin')
                                                                setStateOpenEmailConfirmation('afterconfirmation')
                                                            }}>
                                                            <div className="nav-link-text d-flex gap-2 justify-content-center align-items-center">
                                                            <Form.Check type="radio" onChange={(elem) => {}} className="type-of-opt" checked={(optInMethod == 'singleoptin') ? true : false} name='type-of-opt' id='type-of-opt-single' value={'2'}/>                                                                <span htmlFor={'type-of-opt-single'}>Single Opt-in</span>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Tab.Container>
                                            <div className='content-optin-states'>
                                                {/* <Form.Check type="radio" onChange={(elem) => {}} className="type-of-opt" checked={(optInMethod == 'doubleoptin') ? true : false} name='type-of-opt' id='type-of-opt-double' value={'1'}/>
                                                <Form.Check type="radio" onChange={(elem) => {}} className="type-of-opt" checked={(optInMethod == 'singleoptin') ? true : false} name='type-of-opt' id='type-of-opt-single' value={'2'}/> */}

                                                {(optInMethod == 'doubleoptin') ? <>
                                                    <Alert className='m-3 mb-2 rounded-label shadow-none fs-13 d-flex gap-2 align-items-center' key={'primary'} variant={'primary'}>
                                                        <IconCheck fontSize={13}/>
                                                        <span>Immediately send a confirmation email after submitting the form</span>
                                                    </Alert>
                                                </> : <>
                                                    <Alert className='m-3 mb-2 rounded-label shadow-none fs-13 d-flex gap-2 align-items-center' key={'warning'} variant={'warning'}>
                                                        <IconAlertCircle fontSize={13}/>
                                                        <span>Immediately send a confirmation email after submitting the form</span>
                                                    </Alert>
                                                </>}

                                            </div>
                                            <div className='content-double-optin p-3 pt-1'>
                                                <Tab.Container activeKey={stateOpenEmailConfirmation}>
                                                    {(optInMethod == 'doubleoptin') ? <>
                                                    <Nav variant="tab" className="nav m-3 nav-light nav-tabs-custom nav-custom-slight nav-custom-headerof-accordion nav-justified nav-segmented-tabs nav mx-0 service-type tab-with-iconand-label mt-0">
                                                        <Nav.Item className='px-0'>
                                                            <Nav.Link className='px-1 no-radius no-border-left no-border-top no-border-right' eventKey="emailconfirmation" onClick={(e) => setStateOpenEmailConfirmation('emailconfirmation')}>
                                                                <div className="nav-link-text d-flex gap-2 justify-content-center align-items-center">
                                                                    <span>Email Confirmation</span>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className='px-0'>
                                                            <Nav.Link className='px-1 no-radius no-border-left no-border-top no-border-right' eventKey="afterconfirmation" onClick={(e) => setStateOpenEmailConfirmation('afterconfirmation')}>
                                                                <div className="nav-link-text  d-flex gap-2 justify-content-center align-items-center">
                                                                    <span>After Confirmation</span>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    </> : <></>}
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="emailconfirmation">
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark' htmlFor='dbl-emailconfirmation-sender'>Email Sender</label>
                                                                <Form.Select role='button' name='dbl-emailconfirmation-sender' className='dbl-emailconfirmation-sender' id='dbl-emailconfirmation-sender'>
                                                                    {emailSenderLists.map((sender, index) => {
                                                                        return (<option selected={(formInfoData?.appereances?.forms_config?.opt_in_method?.email_confirmation?.email_senders == sender?.value) ? true : false} value={sender?.value}>{sender?.title}</option>)
                                                                    })}
                                                                </Form.Select>
                                                            </div>
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark'>Email Subject</label>
                                                                <Form.Control type='text' name='dbl-emailconfirmation-subject' defaultValue={formInfoData?.appereances?.forms_config?.opt_in_method?.email_confirmation?.email_subjects} className='dbl-emailconfirmation-subject' id='dbl-emailconfirmation-subject' placeholder='Please Confirm Your Email' minLength={5} maxLength={60}/>
                                                            </div>
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark'>Email Body</label>
                                                                <div className='lexEditorFrame'>
                                                                    <Form.Control as="textarea" rows={6} value={dblEmailConfirmationContent} name='dbl-emailconfirmation-content' id='dbl-emailconfirmation-content' className='dbl-emailconfirmation-content' style={{display: 'none'}}/>
                                                                    <LexicalComposer initialConfig={lexInitialConfig}>
                                                                        <ToolbarPlugin />
                                                                        <div className="editor-inner">
                                                                            <RichTextPlugin
                                                                                contentEditable={<ContentEditable className="editor-input" />}
                                                                                placeholder={<LexPlaceholder />}
                                                                                ErrorBoundary={LexicalErrorBoundary}
                                                                            />
                                                                            <HistoryPlugin />
                                                                            <AutoFocusPlugin />
                                                                            <CodeHighlightPlugin />
                                                                            <ListPlugin />
                                                                            <LinkPlugin />
                                                                            <AutoLinkPlugin />
                                                                            <ListMaxIndentLevelPlugin maxDepth={7} />
                                                                            <EmailConfirmationOnChange/>
                                                                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                                                                        </div>
                                                                    </LexicalComposer>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="afterconfirmation">
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark' htmlFor='dbl-afterconfirmation-sender'>Email Sender</label>
                                                                <Form.Select role='button' name='dbl-afterconfirmation-sender' className='dbl-afterconfirmation-sender' id='dbl-afterconfirmation-sender'>
                                                                    {emailSenderLists.map((sender, index) => {
                                                                        return (<option selected={(formInfoData?.appereances?.forms_config?.opt_in_method?.email_confirmation?.email_senders == sender?.value) ? true : false} value={sender?.value}>{sender?.title}</option>)
                                                                    })}
                                                                </Form.Select>
                                                            </div>
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark'>Email Subject</label>
                                                                <Form.Control type='text' name='dbl-afterconfirmation-subject' defaultValue={formInfoData?.appereances?.forms_config?.opt_in_method?.after_confirmation?.email_subjects} className='dbl-afterconfirmation-subject' id='dbl-afterconfirmation-subject' placeholder='Please Confirm Your Email' minLength={5} maxLength={60}/>
                                                            </div>
                                                            <div className='form-frame mb-3'>
                                                                <label className='text-dark'>Email Body</label>
                                                                <div className='lexEditorFrame'>
                                                                    <Form.Control as="textarea" rows={6} value={dblAfterConfirmationContent} name='dbl-afterconfirmation-content' id='dbl-afterconfirmation-content' className='dbl-afterconfirmation-content' style={{display: 'none'}}/>
                                                                    <LexicalComposer initialConfig={lexInitialConfig}>
                                                                        <ToolbarPlugin />
                                                                        <div className="editor-inner">
                                                                            <RichTextPlugin
                                                                                contentEditable={<ContentEditable className="editor-input" />}
                                                                                placeholder={<LexPlaceholder />}
                                                                                ErrorBoundary={LexicalErrorBoundary}
                                                                            />
                                                                            <HistoryPlugin />
                                                                            <AutoFocusPlugin />
                                                                            <CodeHighlightPlugin />
                                                                            <ListPlugin />
                                                                            <LinkPlugin />
                                                                            <AutoLinkPlugin />
                                                                            <AfterConfirmationOnChange/>
                                                                            <ListMaxIndentLevelPlugin maxDepth={7} />
                                                                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                                                                        </div>
                                                                    </LexicalComposer>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mb-3' eventKey="field_configuration">
                            <Accordion.Header>Field Configuration</Accordion.Header>
                            <Accordion.Body className='content-self-accordion'>
                                <div className='overflow-hidden mb-2'>

                                    <Accordion className='accordion-soft accordion-card field parent-clone-form-configuration-v2'>

                                        {fieldConfigurations.map((field, index) => {

                                            return (<Card className='child-clone-form-configuration-v2'>
                                            <Card.Header>
                                                <CustomToggle eventKey={field?.id} deleteIsEnabled={!field?.default_use} onDeleteEvent={(e) => {

                                                }}>{field?.label}</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={field?.id}>
                                                <Card.Body className='content-self-accordion'>
                                                    <Form.Control name={'fields['+field?.id+']'} type='hidden' defaultValue={field?.id}/>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <div className='form-frame mb-1'>
                                                                <label className='text-dark'>Title</label>
                                                                <Form.Control type='text' name={'title['+field?.id+']'} defaultValue={field?.label} className='title-fields' id='title-fields' placeholder='Ex: Email' minLength={1} maxLength={30}/>
                                                            </div>
                                                            <div className='d-flex gap-1'>
                                                                <div className='form-check form-check-warning'>
                                                                    <Form.Check role='button' type="checkbox" name={'is_required['+field?.id+']'} id={'is-required-field-' + field?.uuid} onChange={(elem) => {}} className="fs-6 mb-0 d-flex justify-content-center" defaultChecked={(field?.is_required == 1) ? true : false} defaultValue={'1'}/>
                                                                </div>
                                                                <label role='button' htmlFor={'is-required-field-' + field?.uuid} className='text-dark fs-15'>Required Field</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>)

                                        })}
                                    </Accordion>
                                    <div className='form-frame mt-4 d-flex gap-2 align-items-center'>
                                        <Form.Check type='checkbox' onChange={(elem) => {}} name='is-label-above-input' className='is-label-above-input' id='is-label-above-input' defaultValue={'1'} defaultChecked={(formInfoData?.appereances?.is_label_above_input) ? true : false}/>
                                        <label role='button' htmlFor='is-label-above-input' className='p-0 m-0'>Show labels above input</label>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='row flex-1'>
                                            <div className='col-lg-6'>
                                                <Form.Select onChange={(e) => {
                                                    setCustomFieldNewKeyValue(e?.target?.value)
                                                }} name='fields-available-toaddedd'>
                                                    <option value={''}>-- Choose Field --</option>
                                                    {customFieldsAvailable?.map((cfa, index) => {
                                                        return (<option disabled={((cfa?.is_disabled) || (cfa?.default_use)) ? true : false} value={cfa?.id} data-is_disabled={(cfa?.is_disabled) ? 1 : 0} data-default_use={(cfa?.default_use) ? 1 : 0} data-uuid={cfa?.uuid}>{cfa?.label}</option>)
                                                    })}
                                                </Form.Select>
                                            </div>
                                            <div className='col-lg-6'>
                                                <button type="button" onClick={(elem) => {
                                                    console.log('customFieldNewKeyValue',customFieldNewKeyValue)
                                                    if((typeof customFieldNewKeyValue != 'undefined') && (customFieldNewKeyValue != '') && (customFieldNewKeyValue != null)){
                                                        const customFieldsAvailableObjKeysSelected = customFieldsAvailableObjKeys[customFieldNewKeyValue]

                                                        setFieldConfigurations(prevState => [...prevState, {
                                                            "id": customFieldsAvailableObjKeysSelected?.id,
                                                            "cf_id": "-1",
                                                            "label": customFieldsAvailableObjKeysSelected?.label,
                                                            "is_required": ((customFieldsAvailableObjKeysSelected?.default_use) ? 1 : 0),
                                                            "is_disabled": customFieldsAvailableObjKeysSelected?.is_disabled,
                                                            "default_use": customFieldsAvailableObjKeysSelected?.default_use,
                                                            "uuid": customFieldsAvailableObjKeysSelected?.uuid
                                                        }]);
                                                        // console.log('customFieldsAvailableObjKeysSelected', customFieldsAvailableObjKeysSelected)
                                                    }
                                                }} className="btn btn-primary flex-1 btn-like-label waves-effect fw-semibold waves-light w-100">
                                                    Add New Field
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Form>
            </div>
            <div className='col-lg-7'>
                <div className='static-preview-content'>
                    <Tab.Container activeKey={previewSelected}>
                        <Nav variant="tabs" className="nav-light nav-justified nav-segmented-tabs nav mt-1 mb-3">
                            <Nav.Item>
                                <Nav.Link eventKey="desktop" onClick={(e) => {
                                    setPreviewSelected('desktop')
                                    setViewAsMobile(false)
                                    reloadIframe()
                                }}>
                                    <span className="nav-link-text">Desktop</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="mobile" onClick={(e) => {
                                    setPreviewSelected('mobile')
                                    setViewAsMobile(true)
                                    reloadIframe()
                                }}>
                                    <span className="nav-link-text">Mobile</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Tab.Container>
                    <div className='forms-preview-area'>
                        <div className='fpa-desktop fpa-child'>
                            <iframe style={(viewAsMobile) ? {width: '380px', margin: '0 auto', display: 'block', transition: 'all 0.5s ease 0s'} : {margin: '0 auto', display: 'block', transition: 'all 0.5s ease 0s'}} key={reloadKey} src={previewIframeUrl} width={'100%'} height={700}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>}</>
   )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(FormsEditorIndex);