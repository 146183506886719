import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import YogiTabler from '../../../components/@yogi-data-table';
import HkDataTable from '../../../components/@hk-data-table';
import { colLists, colFields, colQuarantine, colQuarantineView } from '../Index/AudicencesTableData';
import { IconChevronLeft, IconChevronsDown, IconChevronsUp, IconUserFilled, IconSend2, IconFileDownload, IconCloudUpload, IconFileTypeXls, IconFileTypeCsv, IconFileTypeTxt } from '@tabler/icons-react'

import CodeMirror from '@uiw/react-codemirror';
import Swal from 'sweetalert2';

import { Player } from '@lordicon/react';

const QuarantineIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const history = useHistory();
    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState('lists');
    const [rowData, setRowData] = useState([]);

    const [pageTitle, setPageTitle] = useState('...')

    const customhandlers = (data) => {
        const TpageTitle = data?.informations?.title;
        setPageTitle(TpageTitle)
    }

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'quarantine/view/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
        'id': id
    }

    useEffect(() => {
        toggleCollapsedNav(false);
    }, [])

    useEffect(() => {
        dispatch(updateTopNavTitle({
            breadcrumb: [{
                title: 'Quarantine',
                is_active: true,
                className: 'fs-5 text-dark',
                onClick: (elem) => {
                    history.goBack()
                }
            },
            {
                title: pageTitle,
                is_active: false,
                className: 'fs-5 text-muted',
                onClick: (elem) => {

                }
            }],
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            }
        }));
    }, [pageTitle])

    return (<>
    <div className='p-3 pt-0 mt-3'>
        <div className='p-3 bg-warning bg-opacity-10 rounded w-75 mb-3'>
            <b className='fs-5 text-dark'>What is Quarantine?</b>
            <p className='mt-2 fs-7 text-dark'>Quarantine are for the audiences who have either never opened an email from you or have previously opened an email from you BUT haven’t opened one in more than 2 months (or the specified duration you set). It could also be in the form of a bounce email, an invalid email, or an email that marks you as spam.</p>
        </div>

        <YogiTabler
            column={colQuarantineView}
            rowData={rowData}
            rowsPerPage={10}
            rowSelection={true}
            markStarred={false}
            searchQuery={searchTerm}
            classes="nowrap w-100 mb-4"
            api_endpoint={apiEndpoint}
            api_params={apiParams}
            customhandlers={customhandlers}
            emptydataconfig={{
                heading: 'You have not sent any audiences yet',
                subheading: "Don't be shy to create new audience here. Everything that's been sent will appear here.",
                actions: {
                    className: 'btn btn-primary',
                    title: 'Add Audience',
                    onclick: (elem) => {

                    }
                }
            }}
            sortFields={[
                {
                    title: 'Email',
                    field: 'email'    
                },
                {
                    title: 'Status',
                    field: 'status'    
                }
            ]}
            responsive
        />
    </div>
    </>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(QuarantineIndex);