import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colEmailSenderIndex } from '../Index/EmailSenderTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');


const EmailSendersIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const [refreshTable, setRefreshTable] = useState('')

    const formRef = useRef();

    const [show, setShow] = useState(false)

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'emailsender/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const customhandlers = (data) => {
        //any handler if data loaded
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        const authValue = apiParams?.auth
        const servicesValue = apiParams?.services

        if (!data["auth"]) {
            data["auth"] = authValue;
        } else {
            if (!Array.isArray(data["auth"])) {
                data["auth"] = [data["auth"]];
            }
            data["auth"].push(authValue);
        }
        
        if (!data["services"]) {
            data["services"] = servicesValue;
        } else {
            if (!Array.isArray(data["services"])) {
                data["services"] = [data["services"]];
            }
            data["services"].push(servicesValue);
        }
        
        // Process form data, send AJAX request, etc.
        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'emailsender/save_changes';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            setShow(false)

            if(resJson?.status){
                Swal.fire(
                    resJson?.heading,
                    resJson?.message,
                    'success'
                ).then((e) => {
                    setRefreshTable(new Date().getTime().toString())
                })
            }else{
                Swal.fire(
                    'Analyzing Error',
                    resJson?.message,
                    'error'
                )
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Email Sender',
            actions: {
                title: 'New Email Sender',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: (e) => {
                    setShow(true)
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        setRefreshTable(new Date().getTime().toString())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='p-3 pt-0 mt-3'>
                <div className='p-3 bg-warning bg-opacity-10 rounded w-100 mb-3'>
                    <b className='fs-5 text-dark'>What is Email Sender?</b>
                    <p className='mt-2 fs-7 text-dark'>Here, you can add and manage the email addresses that appear in the 'From' section of your emails.<br/>To improve email delivery and have a more professional "From" address, we recommend you use your domain and set up your own DKIM authentication.</p>
                </div>

                {(refreshTable) ?  <YogiTabler
                    column={colEmailSenderIndex}
                    rowData={rowData}
                    rowsPerPage={10}
                    rowSelection={true}
                    markStarred={false}
                    searchQuery={searchTerm}
                    classes="nowrap w-100 mb-4"
                    api_endpoint={apiEndpoint}
                    api_params={apiParams}
                    customhandlers={customhandlers}
                    emptydataconfig={{
                        heading: 'You have not any email senders yet',
                        subheading: "Don't be shy to create new email sender here. Everything that's been sent will appear here.",
                        actions: {
                            className: 'btn btn-primary',
                            title: 'New Email Sender',
                            onclick: (elem) => {
                                setShow(true)
                            }
                        }
                    }}
                    sortFields={[
                        {
                            title: 'Email',
                            field: 'email'    
                        },
                        {
                            title: 'Status',
                            field: 'status'    
                        }
                    ]}
                    responsive
                /> : <></>}
                

            </div>

            														
            <Modal.Dialog>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-5'>Add New Sender</Modal.Title>
                    </Modal.Header>
                    <Form ref={formRef} onSubmit={handleSubmitAddManual} >
                        <Modal.Body>
                            <Form.Control type='hidden' name='sender_id' className='sender_id' defaultValue={''}/>
                            <div className='mb-3 form-frame'>
                                <label className='form-label'>Sender Name</label>
                                <Form.Control type='text' name='sender_name' className='sender_name' placeholder='Alex from Emailaja.com' required={true}/>
                            </div>
                            <div className='mb-3 form-frame'>
                                <label className='form-label'>Email Address</label>
                                <Form.Control type='email' name='sender_email' className='sender_email' placeholder='alex@emailaja.com' required={true}/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='fs-7' variant="primary" type='submit' name='confirm_email' value={1}>
                                Confirm Email
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Modal.Dialog>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(EmailSendersIndex);