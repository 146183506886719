import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import YogiTabler from '../../../components/@yogi-data-table';
import HkDataTable from '../../../components/@hk-data-table';
import { colLists, colFields, colQuarantine } from './AudicencesTableData';

import CodeMirror from '@uiw/react-codemirror';
import Swal from 'sweetalert2';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const AudiencesIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const searchParamsCurrent = new URLSearchParams(location.search);
    const tabCurrent = searchParamsCurrent.get('tab');

    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState((((typeof tabCurrent != 'undefined') && (tabCurrent != null) && (tabCurrent != '')) ? tabCurrent : 'lists'));
    const [rowData, setRowData] = useState([]);

    const [codeMirrorValue, setCodeMirrorValue] = useState('');

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token')
    }

    const apiEndpointCustomFields = process.env.REACT_APP_URL_VITALS_API + 'audience/customfields';
    const apiParamsCustomFields = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token')
    }

    const apiEndpointQuarantine = process.env.REACT_APP_URL_VITALS_API + 'audience/quarantine';
    const apiParamsQuarantine = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token')
    }

    const submitFromCodeMirror = async(elem) => {
        if((typeof codeMirrorValue != 'undefined') && (codeMirrorValue != null) && (codeMirrorValue != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'paste/lists/dnc';

            const apiParams = {
                'auth': localStorage.getItem('rgd_token'),
                'services': localStorage.getItem('svc_token'),
                'lists_value': codeMirrorValue
            }

            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiParams),
            };

            fetch(apiEndpoint, requestOptions)
            .then(async(response) => {
                var resJson = await response.json()

                if(resJson?.status){

                    Swal.fire(
                        resJson?.heading,
                        resJson?.message,
                        'success'
                    ).then(() => {
                        getDncList()
                    })
                    

                }else{
                    Swal.fire(
                        'Analyzing Error',
                        resJson?.message,
                        'error'
                    )
                }
            })
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
        }
    }

    const codeMirorChange = React.useCallback((val, viewUpdate) => {
        console.log('val:', val);
        setCodeMirrorValue(val);
    }, []);

    const getDncList = async() => {

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/info/dnc';

        const apiParams = {
            'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token')
        }
    
        const bodySend = JSON.stringify(apiParams);
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodySend // Sending current page to fetch data accordingly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const result = await response.json();

        if(result?.status){
            console.log('LISTDNC', result?.info?.lists_dnc)
            setCodeMirrorValue(result?.info?.lists_dnc)
        }
        
    }

    useEffect(() => {

        if(key == 'lists'){
            dispatch(updateTopNavTitle({
                title: 'Lists',
                actions: {
                    title: 'New List',
                    classlists: 'btn btn-primary',
                    icons: '',
                    onclick: null
                }
            }));
        }else if(key == 'fields'){
            dispatch(updateTopNavTitle({
                title: 'Fields',
                actions: {
                    title: 'New Field',
                    classlists: 'btn btn-primary',
                    icons: '',
                    onclick: (el) => {
                        history.push('/create-field');
                    }
                }
            }));
        }else if(key == 'segments'){
            dispatch(updateTopNavTitle({
                title: 'Segments',
                actions: {
                    title: 'New Segment',
                    classlists: 'btn btn-primary',
                    icons: '',
                    onclick: null
                }
            }));
        }else if(key == 'do_not_contacts'){
            dispatch(updateTopNavTitle({
                title: 'Do Not Contacts',
                actions: null
            }));
            getDncList()
        }else if(key == 'quarantine'){
            dispatch(updateTopNavTitle({
                title: 'Quarantine',
                actions: null
            }));
            getDncList()
        }
        
        
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        toggleCollapsedNav(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key])
    return (
        <>
            <Tab.Container activeKey={key}>
                {/* Page Header */}
                <div className="hk-pg-header pg-header-wth-tab pt-0">
                    <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                        <Nav.Item>
                            <Nav.Link eventKey="lists" onClick={(e) => {
                                setKey('lists')

                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('tab', 'lists');
                                history.push({ search: searchParams.toString() });
                            }}>
                                <span className="nav-link-text">Lists</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fields" onClick={(e) => {
                                setKey('fields')

                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('tab', 'fields');
                                history.push({ search: searchParams.toString() });
                            }}>
                                <span className="nav-link-text">Fields</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="segments" onClick={(e) => {
                                setKey('segments')

                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('tab', 'segments');
                                history.push({ search: searchParams.toString() });
                            }}>
                                <span className="nav-link-text">Segments</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="do_not_contacts" onClick={(e) => {
                                setKey('do_not_contacts')

                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('tab', 'do_not_contacts');
                                history.push({ search: searchParams.toString() });
                            }}>
                                <span className="nav-link-text">Do Not Contacts</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="quarantine" onClick={(e) => {
                                setKey('quarantine')

                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('tab', 'quarantine');
                                history.push({ search: searchParams.toString() });
                            }}>
                                <span className="nav-link-text">Quarantine</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                {/* /Page Header */}
                {/* Page Body */}
                <div className="hk-pg-body p-0 m-0">
                    <Tab.Content>
                        <Tab.Pane eventKey="lists">
                            {(key == 'lists') ?  <YogiTabler
                                column={colLists}
                                rowData={rowData}
                                rowsPerPage={10}
                                rowSelection={true}
                                markStarred={false}
                                searchQuery={searchTerm}
                                classes="nowrap w-100 mb-4"
                                api_endpoint={apiEndpoint}
                                api_params={{
                                    'auth': apiParams?.auth,
                                    'services': apiParams?.services,
                                    'section': 'draft'
                                }}
                                emptydataconfig={{
                                    heading: 'You have not sent any lists yet',
                                    subheading: "Don't be shy to create new list here. Everything that's been sent will appear here.",
                                    actions: {
                                        className: 'btn btn-primary',
                                        title: 'New List',
                                        onclick: (elem) => {

                                        }
                                    }
                                }}
                                sortFields={[
                                    {
                                        title: 'Name',
                                        field: 'name'    
                                    },
                                    {
                                        title: 'Active Audiences',
                                        field: 'active_audience'    
                                    },
                                    {
                                        title: 'Open Rate',
                                        field: 'open_rate'    
                                    },
                                    {
                                        title: 'Click Rate',
                                        field: 'click_rate'    
                                    },
                                    {
                                        title: 'Date Create',
                                        field: 'date_create'    
                                    }
                                ]}
                                responsive
                            /> : <></>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fields">
                        {(key == 'fields') ?  <YogiTabler
                                column={colFields}
                                rowData={rowData}
                                rowsPerPage={20}
                                rowSelection={true}
                                markStarred={false}
                                searchQuery={searchTerm}
                                classes="nowrap w-100 mb-4"
                                api_endpoint={apiEndpointCustomFields}
                                api_params={{
                                    'auth': apiParamsCustomFields?.auth,
                                    'services': apiParamsCustomFields?.services
                                }}
                                emptydataconfig={{
                                    heading: 'You have not sent any fields yet',
                                    subheading: "Don't be shy to create new field here. Everything that's been sent will appear here.",
                                    actions: {
                                        className: 'btn btn-primary',
                                        title: 'New Field',
                                        onclick: (el) => {
                                            history.push('/create-field');
                                        }
                                    }
                                }}
                                sortFields={[
                                    {
                                        title: 'Name',
                                        field: 'name'    
                                    },
                                    {
                                        title: 'Type',
                                        field: 'type'    
                                    },
                                    {
                                        title: 'Identifier',
                                        field: 'identifier'    
                                    },
                                    {
                                        title: 'Last Updated',
                                        field: 'last_update'    
                                    }
                                ]}
                                responsive
                            /> : <></>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="segments">
                            <div className='d-flex flex-column justify-content-center gap-3  align-items-center'>
                                <Player ref={playerRef} size={130} icon={ SMILEJson } onComplete={() => playerRef.current?.playFromBeginning()}/>
                                <h5 className='mb-0'>You have not sent any segments yet</h5>
                                <p>Don't be shy to create new segments here. Everything that's been sent will appear here.</p>
                                <a className=' btn btn-primary' onClick={''}>New Segment</a>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="do_not_contacts">
                            <div className='p-3 pt-0'>
                                <p className='fs-7'>If you wish NOT to contact certain people, enter their email address or domain below (one per line). This is also known as your suppression list.</p>
                                <div className='border bg-light rounded p-3 mt-3 w-70'>
                                    <b className='d-block fs-6 text-dark'>For example:</b>
                                    <div className='d-flex gap-5 mt-2'>
                                        <p className='fs-7 w-200p'>megawati@perjuangan.com</p>
                                        <p className='fs-7'>//  We will not send an email to this single email</p>
                                    </div>
                                    <div className='d-flex gap-5 mt-1'>
                                        <p className='fs-7 w-200p'>@perjuangan.com</p>
                                        <p className='fs-7'>//  We will not send an email to email that contains this domain</p>
                                    </div>
                                    <div className='d-flex gap-5 mt-1'>
                                        <p className='fs-7 w-200p'>megawati</p>
                                        <p className='fs-7'>//  We will not send an email to email that contains this username or domain</p>
                                    </div>
                                </div>
                                <CodeMirror onChange={codeMirorChange} value={codeMirrorValue} placeholder={'megawati@perjuangan.com'} height="350px" className='w-70 mt-3 border rounded' />
                                <div className='mt-3'>
                                    <a role='button' onClick={(el) => {
                                        submitFromCodeMirror(el)
                                    }} className='btn btn-primary btn-md'>Save Settings</a>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="quarantine">
                            <div className='p-3 pt-0'>

                                <div className='p-3 bg-warning bg-opacity-10 rounded w-75 mb-3'>
                                    <b className='fs-5 text-dark'>What is Quarantine?</b>
                                    <p className='mt-2 fs-7 text-dark'>Quarantine are for the audiences who have either never opened an email from you or have previously opened an email from you BUT haven’t opened one in more than 2 months (or the specified duration you set). It could also be in the form of a bounce email, an invalid email, or an email that marks you as spam.</p>
                                </div>
                                {(key == 'quarantine') ?  <YogiTabler
                                    column={colQuarantine}
                                    rowData={rowData}
                                    rowsPerPage={20}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpointQuarantine}
                                    api_params={{
                                        'auth': apiParamsQuarantine?.auth,
                                        'services': apiParamsQuarantine?.services
                                    }}
                                    emptydataconfig={{
                                        heading: 'Congratulation!',
                                        subheading: "For now you don't have a quarantine list, that's good! Lets Go Create a Broadcast Now.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'New Broadcast',
                                            onclick: (el) => {

                                            }
                                        }
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'Type',
                                            field: 'type'    
                                        },
                                        {
                                            title: 'Identifier',
                                            field: 'identifier'    
                                        },
                                        {
                                            title: 'Last Updated',
                                            field: 'last_update'    
                                        }
                                    ]}
                                    responsive={false}
                                /> : <></>}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
                {/* /Page Body */}
            </Tab.Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(AudiencesIndex);