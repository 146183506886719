import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
// import HkDataTable from '../../../components/@hk-data-table';
import YogiTabler from '../../../components/@yogi-data-table';
import { colDraft, colOnProgress, colSent } from './DraftTableData';
import { Button, Dropdown } from 'react-bootstrap';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');


const BroadcastIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState('senttab');
    const [rowData, setRowData] = useState([]);

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Broadcast',
            actions: {
                title: 'New Broadcast',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: null
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div>
                <Tab.Container activeKey={key}>
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-0">
                        <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                            <Nav.Item>
                                <Nav.Link eventKey="senttab" onClick={(e) => setKey('senttab')}>
                                    <span className="nav-link-text">Sent</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="drafttab" onClick={(e) => setKey('drafttab')}>
                                    <span className="nav-link-text">Draft</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="progrestab" onClick={(e) => setKey('progrestab')}>
                                    <span className="nav-link-text">On Progress</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body p-0 m-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="senttab">
                                <YogiTabler
                                    column={colSent}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'You have not sent any broadcast yet',
                                        subheading: "Don't be shy to create new broadcast here. Everything that's been sent will appear here.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'New Broadcast',
                                            onclick: (elem) => {
                                                
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'sent'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'Status',
                                            field: 'state'    
                                        },
                                        {
                                            title: 'Receipents',
                                            field: 'receipents'    
                                        },
                                        {
                                            title: 'Open Rate',
                                            field: 'open_rate'    
                                        },
                                        {
                                            title: 'Send At',
                                            field: 'send_at'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="drafttab">
                                <YogiTabler
                                    column={colDraft}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'You have not sent any broadcast yet',
                                        subheading: "Don't be shy to create new broadcast here. Everything that's been sent will appear here.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'New Broadcast',
                                            onclick: (elem) => {

                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'draft'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'Receipents',
                                            field: 'receipents'    
                                        },
                                        {
                                            title: 'Created At',
                                            field: 'created_at'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="progrestab">
                                <YogiTabler
                                    column={colOnProgress}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'You have not sent any broadcast yet',
                                        subheading: "Don't be shy to create new broadcast here. Everything that's been sent will appear here.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'New Broadcast',
                                            onclick: (elem) => {
                                                
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'onprogress'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'Status',
                                            field: 'state'    
                                        },
                                        {
                                            title: 'Receipents',
                                            field: 'receipents'    
                                        },
                                        {
                                            title: 'Send At',
                                            field: 'send_at'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </div>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(BroadcastIndex);