import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Card, Button, ButtonGroup } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { IconChevronLeft, IconUserFilled } from '@tabler/icons-react'
import { updateTopNavTitle } from '../../../redux/action/Header';

import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkProgressBar from '../../../components/@hk-progressbar/HkProgressBar';

import OverviewChart from './OverviewChart';

const ReportBroadcast = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const [key, setKey] = useState('overview');

    const [pageTitle, setPageTitle] = useState('...')
    const [overviewData, setOverviewData] = useState({})
    const [opensData, setOpensData] = useState({})
    const [clicksData, setClicksData] = useState({})
    const [bouncesData, setBouncesData] = useState({})
    const [landedInSpamData, setLandedInSpamData] = useState({})
    const [spamComplainsData, setSpamComplainsData] = useState({})

    const loadData = async(id) => {

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = null;

            if(key == 'overview'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/overview';
            }else if(key == 'opens'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/opens';
            }else if(key == 'clicks'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/clicks';
            }else if(key == 'bounces'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/bounces';
            }else if(key == 'inspam'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/landedspam';
            }else if(key == 'spamcomplains'){
                apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/view/complainspam';
            }
    
            if(apiEndpoint != null){
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'id': id
                }
            
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                // console.log('PageTitle', result?.data?.title)
                setPageTitle(result?.data?.title)
    
                if(key == 'overview'){
                    setOverviewData(result?.data)
                }else if(key == 'opens'){
                    setOpensData(result?.data)
                }else if(key == 'clicks'){
                    setClicksData(result?.data)
                }else if(key == 'bounces'){
                    setBouncesData(result?.data)
                }else if(key == 'inspam'){
                    setLandedInSpamData(result?.data)
                }else if(key == 'spamcomplains'){
                    setSpamComplainsData(result?.data)
                }
            }
        }
        
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: pageTitle,
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            }
        }));
    }, [pageTitle])

    useEffect(() => {
        toggleCollapsedNav(false);
        

        loadData(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key])
    return (
        <>
            <div>
                <Tab.Container activeKey={key}>
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-0">
                        <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                            <Nav.Item>
                                <Nav.Link eventKey="overview" onClick={(e) => setKey('overview')}>
                                    <span className="nav-link-text">Overview</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="opens" onClick={(e) => setKey('opens')}>
                                    <span className="nav-link-text">Opens</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="clicks" onClick={(e) => setKey('clicks')}>
                                    <span className="nav-link-text">Clicks</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="bounces" onClick={(e) => setKey('bounces')}>
                                    <span className="nav-link-text">Bounces</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="inspam" onClick={(e) => setKey('inspam')}>
                                    <span className="nav-link-text">Landed in Spam</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="spamcomplains" onClick={(e) => setKey('spamcomplains')}>
                                    <span className="nav-link-text">Spam complains</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body p-0 m-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="overview">
                                <Row className='p-3 pb-2'>
                                    <Col xxl={7} lg={7} md={7} className="mb-md-4 mb-3">
                                    <Card className="card-border mb-0 h-100">
                                        <Card.Header className="card-header-action">
                                            <h6>Analytics</h6>
                                            <div className="card-action-wrap">
                                                <HkBadge bg={overviewData?.state?.badge} soft className="my-1  me-2" >{overviewData?.state?.title}</HkBadge>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className='p-0'>
                                            <Row>
                                                <Col xxl={6} className='me-0 pe-0'>
                                                    <div className='p-3 border-end border-bottom'>
                                                        <HkProgressBar.Wrapper>
                                                            <div className='d-flex gap-2 align-items-center mb-1 justify-content-between'>
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <HkProgressBar.Label className="mb-0 text-muted fs-7">Total emails sent</HkProgressBar.Label>
                                                                    <div className='d-flex gap-1 align-items-center'>
                                                                        <IconUserFilled size={13} className='text-muted'/>
                                                                        <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.sents?.total}</b>
                                                                    </div>
                                                                </div>
                                                                <b className='fs-6'>{overviewData?.analytics?.sents?.percentage}%</b>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <HkProgressBar now={overviewData?.analytics?.sents?.percentage} variant="primary" rounded size="md" className="flex-1" />
                                                            </div>
                                                        </HkProgressBar.Wrapper>
                                                    </div>
                                                </Col>
                                                <Col xxl={6} className='ps-0 ms-0'>
                                                    <div className='p-3 border-bottom'>
                                                        <HkProgressBar.Wrapper>
                                                            <div className='d-flex gap-2 align-items-center mb-1 justify-content-between'>
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <HkProgressBar.Label className="mb-0 text-muted fs-7">Opened emails</HkProgressBar.Label>
                                                                    <div className='d-flex gap-1 align-items-center'>
                                                                        <IconUserFilled size={13} className='text-muted'/>
                                                                        <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.opened?.total}</b>
                                                                    </div>
                                                                </div>
                                                                <b className='fs-6'>{overviewData?.analytics?.opened?.percentage}%</b>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <HkProgressBar now={overviewData?.analytics?.opened?.percentage} variant="primary" rounded size="md" className="flex-1" />
                                                            </div>
                                                        </HkProgressBar.Wrapper>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6} className='me-0 pe-0'>
                                                    <div className='p-3 border-end border-bottom'>
                                                        <HkProgressBar.Wrapper>
                                                            <div className='d-flex gap-2 align-items-center mb-1 justify-content-between'>
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <HkProgressBar.Label className="mb-0 text-muted fs-7">Link clicked</HkProgressBar.Label>
                                                                    <div className='d-flex gap-1 align-items-center'>
                                                                        <IconUserFilled size={13} className='text-muted'/>
                                                                        <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.clicks?.total}</b>
                                                                    </div>
                                                                </div>
                                                                <b className='fs-6'>{overviewData?.analytics?.clicks?.percentage}%</b>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <HkProgressBar now={overviewData?.analytics?.clicks?.percentage} variant="primary" rounded size="md" className="flex-1" />
                                                            </div>
                                                        </HkProgressBar.Wrapper>
                                                    </div>
                                                </Col>
                                                <Col xxl={6} className='ps-0 ms-0'>
                                                    <div className='p-3 border-bottom'>
                                                        <HkProgressBar.Wrapper>
                                                            <div className='d-flex gap-2 align-items-center mb-1 justify-content-between'>
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <HkProgressBar.Label className="mb-0 text-muted fs-7">Click open rate</HkProgressBar.Label>
                                                                    <div className='d-flex gap-1 align-items-center'>
                                                                        <IconUserFilled size={13} className='text-muted'/>
                                                                        <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.opens?.total}</b>
                                                                    </div>
                                                                </div>
                                                                <b className='fs-6'>{overviewData?.analytics?.opens?.percentage}%</b>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <HkProgressBar now={overviewData?.analytics?.opens?.percentage} variant="primary" rounded size="md" className="flex-1" />
                                                            </div>
                                                        </HkProgressBar.Wrapper>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className='m-3 mb-0 border-bottom d-flex gap-3 align-items-center justify-content-between pb-3'>
                                                        <div className='d-flex gap-3 align-items-center'>
                                                            <label className='text-muted fs-7'>Unsubscribed</label>
                                                            <div className='d-flex gap-1'>
                                                                <IconUserFilled size={13} className='text-muted'/>
                                                                <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.unsubs?.total}</b>
                                                            </div>
                                                        </div>
                                                        <b className='fs-6'>{overviewData?.analytics?.unsubs?.percentage}%</b>
                                                    </div>
                                                </Col>
                                                <Col xxl={6}>
                                                    <div className='m-3 mb-0 border-bottom d-flex gap-3 align-items-center justify-content-between pb-3'>
                                                        <div className='d-flex gap-3 align-items-center'>
                                                            <label className='text-muted fs-7'>Landed in spam folder</label>
                                                            <div className='d-flex gap-1'>
                                                                <IconUserFilled size={13} className='text-muted'/>
                                                                <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.landedspam?.total}</b>
                                                            </div>
                                                        </div>
                                                        <b className='fs-6'>{overviewData?.analytics?.landedspam?.percentage}%</b>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className='m-3 d-flex gap-3 align-items-center justify-content-between pb-0'>
                                                        <div className='d-flex gap-3 align-items-center'>
                                                            <label className='text-muted fs-7'>Bounces</label>
                                                            <div className='d-flex gap-1'>
                                                                <IconUserFilled size={13} className='text-muted'/>
                                                                <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.bounces?.total}</b>
                                                            </div>
                                                        </div>
                                                        <b className='fs-6'>{overviewData?.analytics?.bounces?.percentage}%</b>
                                                    </div>
                                                </Col>
                                                <Col xxl={6}>
                                                    <div className='m-3 d-flex gap-3 align-items-center justify-content-between pb-0'>
                                                        <div className='d-flex gap-3 align-items-center'>
                                                            <label className='text-muted fs-7'>Spam complaints</label>
                                                            <div className='d-flex gap-1'>
                                                                <IconUserFilled size={13} className='text-muted'/>
                                                                <b className='fs-8 text-muted' style={{lineHeight: 'normal'}}>{overviewData?.analytics?.complainsspam?.total}</b>
                                                            </div>
                                                        </div>
                                                        <b className='fs-6'>{overviewData?.analytics?.complainsspam?.percentage}%</b>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    </Col>
                                    <Col xxl={5} lg={5} md={5} className="mb-md-4 mb-3">
                                        <Card className="card-border mb-0 h-100">
                                            <Card.Body className='p-3'>
                                                <div className='overflow-auto' style={{maxHeight: '288px'}}>
                                                    {(overviewData?.screenshoots?.[0]) ? <img className='w-100' src={overviewData?.screenshoots?.[0]} alt='PreviewBc'/> : <></>}
                                                    
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='p-3 pt-0'>
                                    <Col xxl={12}>
                                        <Card className="card-border mb-0 h-100">
                                            <Card.Header className="card-header-action">
                                                <h6>Opens and clicks for the first 24 hours</h6>
                                            </Card.Header>
                                            <Card.Body>
                                                <OverviewChart categories={overviewData?.charts?.charts_categories} series={overviewData?.charts?.charts_series}/>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="opens">
                            </Tab.Pane>
                            <Tab.Pane eventKey="clicks">
                            </Tab.Pane>
                            <Tab.Pane eventKey="inspam">
                            </Tab.Pane>
                            <Tab.Pane eventKey="bounces">
                            </Tab.Pane>
                            <Tab.Pane eventKey="spamcomplains">
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </div>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(ReportBroadcast);