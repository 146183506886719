import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';

import { colForms } from './FormsTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const FormsIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const formRef = useRef();

    const [show, setShow] = useState(false)

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'form/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const customhandlers = (data) => {
        //any handler if data loaded
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Forms',
            actions: {
                title: 'New Form',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: (e) => {
                    history.push('/form/editor');
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
        <div className='p-3 pt-0 mt-3'>
            <YogiTabler
                column={colForms}
                rowData={rowData}
                rowsPerPage={10}
                rowSelection={true}
                markStarred={false}
                searchQuery={searchTerm}
                classes="nowrap w-100 mb-4"
                api_endpoint={apiEndpoint}
                api_params={apiParams}
                customhandlers={customhandlers}
                emptydataconfig={{
                    heading: 'You have not sent any forms yet',
                    subheading: "Don't be shy to create new forms here. Everything that's been sent will appear here.",
                    actions: {
                        className: 'btn btn-primary',
                        title: 'New Form',
                        onclick: (elem) => {
                            history.push('/form/editor');
                        }
                    }
                }}
                sortFields={[
                    {
                        title: 'Email',
                        field: 'email'    
                    },
                    {
                        title: 'Status',
                        field: 'status'    
                    }
                ]}
                responsive
            />
        </div>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(FormsIndex);