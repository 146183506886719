import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Card, Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { IconChevronLeft, IconChevronsDown, IconChevronsUp, IconUserFilled, IconSend2, IconFileDownload, IconCloudUpload, IconFileTypeXls, IconFileTypeCsv, IconFileTypeTxt } from '@tabler/icons-react'
import { updateTopNavTitle } from '../../../redux/action/Header';
import {useDropzone} from 'react-dropzone'
import Swal from 'sweetalert2';

import CodeMirror from '@uiw/react-codemirror';
// import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
// import { languages } from '@codemirror/language-data';

import YogiTabler from '../../../components/@yogi-data-table';
import { colSubscribers } from '../Index/AudicencesTableData';

import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkProgressBar from '../../../components/@hk-progressbar/HkProgressBar';

const AddNewAudiences = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const formRef = useRef();

    const [pageTitle, setPageTitle] = useState('...')
    const [key, setKey] = useState('fromfile');
    const [codeMirrorValue, setCodeMirrorValue] = useState('');
    const [fieldsExport, setFieldsExport] = useState([])
    
    const [hasBeenUploaded, setHasBeenUploaded] = useState(false)
    const [uploadedFileInfo, setUploadedFileInfo] = useState({})

    const [stateShownManualCustomFields, setStateShownManualCustomFields] = useState(false)

    const fileUploadedIcons = [];
    fileUploadedIcons['xlsx'] = 'https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/1_excel.svg'
    fileUploadedIcons['csv'] = 'https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/2_csv.svg'
    fileUploadedIcons['txt'] = 'https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/3_txt.svg'
    
    const [buttonsDownloadsTemplate, setButtonsDownloadsTemplate] = useState([{
        'id': 'xlsx',
        'title': 'Excel (.xlsx)',
        'icon': <IconFileTypeXls size={16}/>,
        'isopen' : false
    }, {
        'id': 'csv',
        'title': 'CSV (.csv)',
        'icon': <IconFileTypeCsv size={16}/>,
        'isopen' : false
    }, {
        'id': 'txt',
        'title': 'TXT (.txt)',
        'icon': <IconFileTypeTxt size={16}/>,
        'isopen' : false
    }])
    
    const toggleDropdown = (id) => {
        setButtonsDownloadsTemplate(prevState => {
          return prevState.map(button => {
            if (button.id === id) {
              return {
                ...button,
                isopen: !button.isopen
              };
            }
            return button;
          });
        });
    };

    const submitFromCodeMirror = async(elem) => {
        if((typeof codeMirrorValue != 'undefined') && (codeMirrorValue != null) && (codeMirrorValue != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'paste/lists/indentify';

            const apiParams = {
                'auth': localStorage.getItem('rgd_token'),
                'services': localStorage.getItem('svc_token'),
                'id': id,
                'paste_value': codeMirrorValue
            }

            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiParams),
            };

            fetch(apiEndpoint, requestOptions)
            .then(async(response) => {
                var resJson = await response.json()

                if(resJson?.status){

                    history.push('/indentify-fields/' + resJson?.id);

                }else{
                    Swal.fire(
                        'Analyzing Error',
                        resJson?.message,
                        'error'
                    )
                }
            })
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
        }
    }

    const codeMirorChange = React.useCallback((val, viewUpdate) => {
        console.log('val:', val);
        setCodeMirrorValue(val);
      }, []);

    function constructFormData(btnId) {
        const formData = [];
        fieldsExport.forEach(value => {
            const checkbox = document.getElementById(btnId + '_' + value.id);
            if (checkbox.checked) {
                formData.push({ id: value.id, title: value.title });
            }
        });
        return formData;
    }

    function handleDownloadClick(btnId, type) {
        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/templates/download';
        const formData = constructFormData(btnId);

        const apiParams = {
            'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
            'id': id,
            'type': type,
            'fields': formData
        }
    
        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiParams),
        })
        .then(async(response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if(result?.status){
                window.open(result?.link_download, '_blank');
            }else{
                Swal.fire(
                    'Failed!',
                    result?.message,
                    'error'
                )
            }
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    const handleButtonClick = (id) => {
        setButtonsDownloadsTemplate(prevState => {
            return prevState.map(button => {
            if (button.id === id) {
                return {
                ...button,
                isopen: false
                };
            }
            return button;
            });
        });
        // Add your button click functionality here
    };

    const handleSelectAll = (id) => {
        setButtonsDownloadsTemplate(prevState => {
            return prevState.map(button => {
                if (button.id === id) {
                    // Toggle selection state based on current state
                    const allCheckboxValues = fieldsExport.map(value => value.id);
                    const selectedCheckboxes = button.selectedCheckboxes || [];
                    const enabledCheckboxes = allCheckboxValues.filter(value => !fieldsExport.find(field => field.id === value)?.is_default);
                    const newSelectedCheckboxes = selectedCheckboxes.length === enabledCheckboxes.length ? [] : enabledCheckboxes;
                    return { ...button, selectedCheckboxes: newSelectedCheckboxes };
                }
                return button;
            });
        });
    };

    const handleCheckboxChange = (buttonId, checkboxId) => {
        setButtonsDownloadsTemplate(prevState => {
            return prevState.map(button => {
                if (button.id === buttonId) {
                    const { selectedCheckboxes } = button;
                    if (selectedCheckboxes.includes(checkboxId)) {
                        button.selectedCheckboxes = selectedCheckboxes.filter(id => id !== checkboxId);
                    } else {
                        button.selectedCheckboxes = [...selectedCheckboxes, checkboxId];
                    }
                }
                return button;
            });
        });
    };

    const identifyColoumns = async(id, fileuploaded) => {
        if(
            ((typeof id != 'undefined') && (id != null) && (id != '')) && 
            ((typeof fileuploaded != 'undefined') && (fileuploaded != null) && (fileuploaded != ''))
        ){


            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'import/lists/indentify';

            const myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=l5lutjblvotl2n0p61cvqrko93");

            const formData = new FormData();
            formData.append('auth', localStorage.getItem('rgd_token')); 
            formData.append('services', localStorage.getItem('svc_token')); 
            formData.append('id', id); 
            formData.append('file', fileuploaded); 

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow"
            };

            fetch(apiEndpoint, requestOptions)
            .then(async(response) => {
                var resJson = await response.json()

                if(resJson?.status){

                    history.push('/indentify-fields/' + resJson?.id);

                }else{
                    Swal.fire(
                        'Analyzing Error',
                        resJson?.message,
                        'error'
                    )
                }
            })
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
        }

    }
    

    const getInfo = async(id) => {

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'audience/info/lists';

            const apiParams = {
                'auth': localStorage.getItem('rgd_token'),
                'services': localStorage.getItem('svc_token'),
                'id': id
            }
        
            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){
                setPageTitle(result?.info?.title)
                setFieldsExport(result?.info?.fields)
            }
        }
        
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });
        
        // Process form data, send AJAX request, etc.
        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'subscribers/add/manual';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){

                history.push('/list-view/' + resJson?.id);

            }else{
                Swal.fire(
                    'Analyzing Error',
                    resJson?.message,
                    'error'
                )
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    };

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            
            if(
                (file?.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                ((file?.type == 'application/vnd.ms-excel') || (file?.type == 'text/csv')) || 
                ((file?.type == 'text/plain'))
            ){

                const fileSizeInBytes = file?.size; // Assuming the size property exists in your fileInfo object
                const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes

                if(fileSizeInBytes <= maxSizeInBytes){

                    let icon = '';
                    if(file?.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                        icon = fileUploadedIcons['xlsx']
                    }else if((file?.type == 'application/vnd.ms-excel') || (file?.type == 'text/csv')){
                        icon = fileUploadedIcons['csv']
                    }else if((file?.type == 'text/plain')){
                        icon = fileUploadedIcons['txt']
                    }

                    setUploadedFileInfo({
                        name: file?.name,
                        type: file?.type,
                        size: file?.size,
                        icon: icon,
                        file: file
                    })
                    setHasBeenUploaded(true)
                }else{
                    Swal.fire(
                        'File Error',
                        'Max size allowed is 2MB',
                        'error'
                    )
                }
                
            }else{
                Swal.fire(
                    'File Error',
                    'File format is not supported / your file is corrupt',
                    'error'
                )
            }

          })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        dispatch(updateTopNavTitle({
            breadcrumb: [{
                title: pageTitle,
                is_active: false,
                className: 'fs-5 text-dark',
                onClick: (elem) => {

                }
            }, {
                title: 'Add Audience',
                is_active: true,
                className: 'fs-5 text-muted',
                onClick: (elem) => {
                    history.goBack()
                }
            }],
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            }
        }));
    }, [pageTitle])

    useEffect(() => {
        toggleCollapsedNav(false);
        getInfo(id)
    }, [])

    return (<>
        <div className='mt-2'>
            <Tab.Container activeKey={key}>
                <div className="hk-pg-header pg-header-wth-tab pt-0">
                    <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                        <Nav.Item>
                            <Nav.Link eventKey="fromfile" onClick={(e) => setKey('fromfile')}>
                                <span className="nav-link-text">Import from file</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fromcopypaste" onClick={(e) => setKey('fromcopypaste')}>
                                <span className="nav-link-text">Copy/paste from Excel</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="addmanually" onClick={(e) => setKey('addmanually')}>
                                <span className="nav-link-text">Add Manually</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="hk-pg-body p-0 m-0">
                    <Tab.Content>
                        <Tab.Pane eventKey="fromfile">
                            <div className='p-3 pt-0 pb-0'>
                                <b className='fs-5 text-dark mb-2 d-block'>Import disclaimer</b>
                                <p className='text-muted'>We’ll automatically clean duplicate instances of email addresses from the list.<br/>
    Importing does not send any confirmation emails to your list, because we trust you’ve already received permission.<br/>
    Make sure everyone on your list actually signed up for it and granted you permission to email them.</p>
                            </div>
                            <hr className='ms-3 me-3'/>
                            <div className='p-3 pt-0 pb-0'>
                                <b className='fs-5 text-dark mb-2 d-block'>Upload a file</b>
                                <p className='text-muted'>Before uploading your contact list, make sure you have enough identifiers in the correct format.<br/>
The file needs to be in a XLSX, CSV or TXT format.</p>

                                {(!hasBeenUploaded) ? <div className='parent-dragdrop-file w-70' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ? <>
                                        <IconCloudUpload size={50} className='mb-2'/>
                                            <p className='title-dragdrop-file text-semi-dark fw-bolder'>Drop the files here ...</p>
                                            <p className='text-muted'>or select it from your computer</p>
                                        </> :
                                        <>
                                            <IconCloudUpload size={50} className='mb-2'/>
                                            <p className='title-dragdrop-file text-semi-dark fw-bolder'>Drag and drop a file here</p>
                                            <p className='text-muted'>or select it from your computer</p>
                                        </>
                                    }
                                </div>
                                : <div className='d-flex gap-3 p-3 border w-70 rounded justify-content-between mt-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img style={{width: '25px'}} src={uploadedFileInfo?.icon} alt=''/>
                                    <b className='fs-7'>{uploadedFileInfo?.name}</b>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <a role='button' onClick={(elem) => {
                                        setUploadedFileInfo({})
                                        setHasBeenUploaded(false)
                                    }} className='flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm'>
                                        <span className='fs-7'>Remove</span>
                                    </a>
                                    <a role='button' onClick={(elem) => {
                                        identifyColoumns(id, uploadedFileInfo?.file)
                                    }} className='btn btn-primary btn-sm d-flex gap-1 align-items-center'>
                                        <IconSend2 size={15}/>
                                        <span className='d-inline fs-7'>Process</span>
                                    </a>
                                </div>
                            </div>}
                                
                                
                            </div>
                            <p className='text-muted ms-3 me-3 mt-3 mb-3 fs-6'>Need a template? You can download here</p>
                            <div className='d-flex gap-3 align-items-center ps-3 pe-3'>
                                {buttonsDownloadsTemplate.map((btnVal, btnIndex) => (
                                    <Dropdown drop='up' show={btnVal?.isopen} onToggle={() => toggleDropdown(btnVal?.id)}>
                                        <Dropdown.Toggle variant="flush-dark" className="flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm no-caret" aria-expanded="false" data-bs-toggle="dropdown">
                                            <span className='gap-2'>
                                                {btnVal?.icon}
                                                <span className='fs-7' style={{textTransform: 'none'}}>{btnVal?.title}</span>
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="start" className='w-300p p-3'>
                                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                                <Dropdown.Header as="h6" className='fs-8 ps-0 pt-0 pb-0'>SELECT FIELDS</Dropdown.Header>
                                                <a role='button' onClick={() => handleSelectAll(btnVal?.id)} className='fs-8 text-dark'>Select All</a>
                                            </div>
                                            <div className='p-2 border rounded custom-mh-select-fields'>
                                                <div className='d-flex flex-column gap-1'>
                                                    {fieldsExport.map((value, key) => (
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <Form.Check
                                                                name='fields[]'
                                                                type="checkbox"
                                                                id={btnVal?.id+'_'+value?.id}
                                                                defaultChecked={((btnVal.selectedCheckboxes && btnVal.selectedCheckboxes.includes(value?.id)) || (value?.is_default))}
                                                                disabled={(value?.is_default) ? true : false}
                                                                defaultValue={value?.id}
                                                                onChange={() => handleCheckboxChange(btnVal?.id, value?.id)}
                                                            />
                                                            {(value?.is_default) ? 
                                                            <label for={btnVal?.id+'_'+value?.id} className='text-muted fs-8'>{value?.title}</label> : 
                                                            <label for={btnVal?.id+'_'+value?.id} className='text-dark fs-8'>{value?.title}</label>}
                                                            
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='d-flex gap-2 align-items-center mt-2'>
                                                <Button onClick={(elem) => {
                                                    handleDownloadClick(btnVal?.id, btnVal?.id)
                                                    handleButtonClick(btnVal?.id)
                                                }} className='btn btn-primary btn-sm'>
                                                    <IconFileDownload size={13}/> <span className='d-inline fs-8'>Download</span>
                                                </Button>
                                                <Button onClick={() => handleButtonClick(btnVal?.id)} className='btn btn-light btn-sm'>
                                                    <span className='d-inline fs-8'>Close</span>
                                                </Button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ))}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fromcopypaste">
                            <div className='p-3 pt-0 pb-0'>
                                <b className='fs-5 text-dark mb-2 d-block'>Import disclaimer</b>
                                <p className='text-muted'>We’ll automatically clean duplicate instances of email addresses from the list.<br/>
    Importing does not send any confirmation emails to your list, because we trust you’ve already received permission.<br/>
    Make sure everyone on your list actually signed up for it and granted you permission to email them.</p>
                            </div>
                            <hr className='ms-3 me-3'/>
                            <div className='p-3 pt-0 pb-0'>
                                <b className='fs-5 text-dark mb-2 d-block'>Copy/paste from Excel</b>
                                <CodeMirror onChange={codeMirorChange} value={''} placeholder={'Email Address...    Fullname...'} height="350px" className='w-90 border rounded' />
                            </div>
                            <div className='p-3 pt-0 pb-0 mt-3'>
                                <a role='button' onClick={(el) => {
                                    submitFromCodeMirror(el)
                                }} className='btn btn-primary btn-md'>Import Audiences</a>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="addmanually">
                            <div className='p-3 pt-0 pb-0'>
                                <b className='fs-5 text-dark mb-2 d-block'>Audience details</b>
                            </div>
                            <Form ref={formRef} onSubmit={handleSubmitAddManual} >
                                <Form.Control type='hidden' name='id' value={id} id='id' className='id'/>
                                <div className='p-3 w-35'>
                                    <Form.Group controlId="validataionEmailAddress">
                                        <Form.Label htmlFor="email_address">Email Address</Form.Label>
                                        <Form.Control type="email" name='email_address' id="email_address" aria-describedby="emailAddressHelpBlock" required={true}/>
                                    </Form.Group>
                                    <Form.Group className='mt-3' controlId="validataionFullName">
                                        <Form.Label htmlFor="full_name">Full Name</Form.Label>
                                        <Form.Control type="text" name='full_name' id="full_name" aria-describedby="fullNameHelpBlock" required={true}/>
                                    </Form.Group>
                                    {(stateShownManualCustomFields) ? <div className='area-custom-fields'>
                                        {fieldsExport?.filter(vExport => vExport.is_default === false)?.map((vExport, kExport) => (
                                            <Form.Group className='mt-3'>
                                                <Form.Label htmlFor={'customf_'+vExport?.id}>{vExport?.title}</Form.Label>
                                                {((vExport?.type_field == 'dropdown') || (vExport?.type_field == 'dropdown_multi')) ? <>
                                                <Form.Select multiple={(vExport?.type_field == 'dropdown_multi')} name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}>
                                                    {vExport?.values?.lists?.map((vl, kl) => {
                                                        if((typeof vl != 'undefined') && (vl != null) && (vl != '')){
                                                            return (<option value={vl}>{vl}</option>)
                                                        }
                                                    })}
                                                </Form.Select>
                                                </> : <></>}

                                                {(vExport?.type_field == 'date') ? <>
                                                <Form.Control type="date" name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}/>
                                                </> : <></>}

                                                {(vExport?.type_field == 'date_time') ? <>
                                                <Form.Control type="datetime-local" name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}/>
                                                </> : <></>}

                                                {(vExport?.type_field == 'phone') ? <>
                                                <Form.Control type="tel" name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}/>
                                                </> : <></>}

                                                {(vExport?.type_field == 'textarea') ? <>
                                                <Form.Control as="textarea" name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}/>
                                                </> : <></>}

                                                {(vExport?.type_field == 'text') ? <>
                                                <Form.Control type="text" name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}/>
                                                </> : <></>}

                                                {((vExport?.type_field == 'rating') || (vExport?.type_field == 'years')) ? <>
                                                    <Form.Select multiple={(vExport?.type_field == 'dropdown_multi')} name={'custom_fields['+'customf_'+vExport?.id+']'} id={'customf_'+vExport?.id} aria-describedby={'customf_'+vExport?.id+'HelpBlock'}>
                                                        {(() => {
                                                            const options = [];
                                                            for (let index = vExport?.values?.min; index <= vExport?.values?.max; index++) {
                                                            options.push(
                                                                <option key={index} value={index}>
                                                                {index}
                                                                </option>
                                                            );
                                                            }
                                                            return options;
                                                        })()}
                                                    </Form.Select>
                                                </> : <></>}

                                                
                                            </Form.Group>
                                        ))}
                                    </div> : <></>}
                                    <a role='button' onClick={(el) => {
                                        setStateShownManualCustomFields(!stateShownManualCustomFields)
                                    }} className='d-flex gap-1 align-items-center mt-3 text-muted'>
                                        {(stateShownManualCustomFields) ? 
                                        <>
                                            <span className='fs-7'>Show less fields</span>
                                            <IconChevronsUp size={15}/>
                                        </>: 
                                        <>
                                            <span className='fs-7'>Show more fields</span>
                                            <IconChevronsDown size={15}/>
                                        </>}
                                        
                                    </a>
                                    
                                </div>
                                <div className='p-3 pt-0'>
                                    <Button type='submit' name='save_add_audience' value={'1'} className='btn btn-primary btn-md'>
                                        Add Audience
                                    </Button>
                                </div>
                            </Form>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    </>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(AddNewAudiences);