import { UPDATE_TITLE } from "../constants/Header";

const initialState = {
  title: 'Default Title',
};

const HeaderReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_TITLE:
        return {
          ...state,
          title: action.payload,
        };
      default:
        return state;
    }
};
  
export default HeaderReducer;