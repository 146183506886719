import { Archive, Copy, Edit, MoreVertical, Trash, Trash2, Eye } from 'react-feather';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import Swal from 'sweetalert2';

//Custom Action Container
const actionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;

    const handleDuplicateBroadcast = (elem, id) => {
        Swal.fire({
            title: 'Confirmation',
            text: "Are you duplicate this broadcast?",
            icon: 'question',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, duplicate it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {
                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/duplicate';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
                    'duplicate': id
                }
            
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }
            
            }
        })
    }

    const handleDeleteBroadcast = (elem, id) => {
        // console.log('ID Delete', id)

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/purge';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                {(actions?.use_continue_editing) ? <Button as={Link} to={data?.reportBroadcastLink} className="btn btn-outline-light btn-sm" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast" style={{lineHeight:'2'}}>
                    Continue Editing
                </Button> : <></>}

                {(actions?.use_view_button) ? <Button variant="flush-dark" as={Link} to={data?.reportBroadcastLink} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Eye />
                        </span>
                    </span>
                </Button> : <></>}

                {(actions?.use_duplicate_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDuplicateBroadcast(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Duplicate Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Copy />
                        </span>
                    </span>
                </Button> : <></>}
                
                {(actions?.use_delete_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDeleteBroadcast(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                    <span className="icon">
                        <span className="feather-icon">
                            <Trash />
                        </span>
                    </span>
                </Button> : <></>}
               
            </div>
        </div>
    )
}


export const colSent = [
    {
        accessor: "name",
        title: "Name",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/broadcast-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }

           
        },
    },
    {
        accessor: "status",
        title: "Status",
        sort: true,
        elipsis: false,
    },
    {
        accessor: "receipents",
        title: "Receipents",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "rate",
        title: "Open Rate",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "send_at",
        title: "Send At",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];


export const colDraft = [
    {
        accessor: "name",
        title: "Name",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/broadcast-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "tracking",
        title: "Tracking",
        sort: false,
        elipsis: false,
    },
    {
        accessor: "receipents",
        title: "Receipents",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "created_at",
        title: "Created At",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];

export const colOnProgress = [
    {
        accessor: "name",
        title: "Name",
        elipsis: true,
        sort: true,
        cellFormatter: (data) => {
            try {
                var dataJson = JSON.parse(data)

                if((typeof dataJson === 'object' && dataJson !== null)){
                    return (<Link className="table-text-link" to={'/broadcast-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                }else{
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
            } catch (e) {
                return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
            }
        },
    },
    {
        accessor: "status",
        title: "Status",
        sort: true,
        elipsis: false,
        cellFormatter: (cell) => <span className="text-truncate mw-150p d-block">{cell}</span>,
    },
    {
        accessor: "receipents",
        title: "Receipents",
        elipsis: false,
        sort: true,
    },
    {
        accessor: "send_at",
        title: "Send At",
        elipsis: false,
        sort: true
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];