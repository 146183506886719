import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Card, Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { IconChevronLeft, IconTrash, IconChevronsDown, IconChevronsUp, IconUserFilled, IconSend2, IconFileDownload, IconCloudUpload, IconFileTypeXls, IconFileTypeCsv, IconFileTypeTxt } from '@tabler/icons-react'
import { updateTopNavTitle } from '../../../redux/action/Header';
import {useDropzone} from 'react-dropzone'
import Swal from 'sweetalert2';

const EditorCustomField = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const formRef = useRef();
    const [pageTitle, setPageTitle] = useState('New Field')
    const [fieldType, setFieldType] = useState('...')
    const [fieldInfo, setFieldInfo] = useState({})

    const [multiValuesElement, setMultiValuesElement] = useState([]);

    const appendMultiValues = () => {
        const newDivList = [...multiValuesElement, ""];
        setMultiValuesElement(newDivList);
    };
    
    // Function to remove the last div
    const removeMultiValues = (indexToRemove) => {
        const newDivList = multiValuesElement.filter((_, index) => index !== indexToRemove);
        if (newDivList.length === 0) {
            appendMultiValues(); // Automatically add a new div if the list becomes empty
        } else {
            setMultiValuesElement(newDivList);
        }
    };

    useEffect(() => {

        console.log('fieldType', fieldType)
       
        if (fieldType === 'dropdown' || fieldType === 'dropdown_multi' || fieldType === 'checkbox' || fieldType === 'radio') {
            
            setMultiValuesElement([""]);
        }else{
            setMultiValuesElement([]);
        }
      }, [fieldType]); // Run this effect whenever fieldType changes
    

    const typeFields = JSON.parse('{"text":"Text","textarea":"Textarea","dropdown":"Dropdown","dropdown_multi":"Dropdown Multi Select","checkbox":"Checkbox","radio":"Radio","phone":"Phone","date":"Date","date_time":"Datetime","url":"URL","rating":"Rating","years":"Years Range","geocountry":"Country","geostate":"State","geocity":"City","consent":"Consent Check \/ GDPR"}');
    
    const getInfo = async(id) => {

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'field/info';

            const apiParams = {
                'auth': localStorage.getItem('rgd_token'),
                'services': localStorage.getItem('svc_token'),
                'id': id
            }
        
            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){
                setFieldInfo(result?.info)
                setFieldType(result?.info?.type)

                var list_values = result?.info?.list_values
                if((typeof list_values != 'undefined') && (list_values != null) && (list_values != '')){
                    try{
                        list_values = JSON.parse(list_values)

                        setTimeout(() => {
                            setMultiValuesElement(list_values);
                            // console.log('LV', list_values)
                        }, 200);

                    }catch(erx){

                    }
                }

            }
        }
        
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            breadcrumb: [{
                title: 'Manage Fields',
                is_active: false,
                className: 'fs-5 text-dark',
                onClick: (elem) => {
                    history.goBack()
                }
            }, {
                title: pageTitle,
                is_active: true,
                className: 'fs-5 text-muted',
                onClick: (elem) => {
                    
                }
            }],
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            }
        }));
    }, [pageTitle])


    useEffect(() => {
        toggleCollapsedNav(false);

        console.log('id', id)

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            setPageTitle('Edit Field')
            getInfo(id)
        }

    }, [])

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        data['auth'] = localStorage.getItem('rgd_token')
        data['services'] = localStorage.getItem('svc_token')

        // Process form data, send AJAX request, etc.
        // console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'fields/add';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){

                history.push('/audiences');

            }else{
                Swal.fire(
                    'Add Field Error',
                    resJson?.message,
                    'error'
                )
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    };

    return (<>
    <div className='mt-2'>
        <div className='p-3 pt-0 pb-0'>
            <b className='fs-5 text-dark mb-2 d-block'>Field details</b>
        </div>
        <Form ref={formRef} onSubmit={handleSubmitAddManual}>
            <Form.Control type='hidden' name='id' value={fieldInfo?.id} className='id' id='id'/>
            <div className='p-3 w-35'>
                <Form.Group controlId="validataionNameField">
                    <Form.Label htmlFor="name_field">Name</Form.Label>
                    <Form.Control type="text" defaultValue={fieldInfo?.name} name='name_field' id="name_field" aria-describedby="nameFieldHelpBlock" required={true}/>
                </Form.Group>
                <Form.Group controlId="validataionTypeField" className='mt-3'>
                    <Form.Label htmlFor="type_field">Type</Form.Label>
                    <Form.Select role='button' name='type_field' id='type_field' onChange={(elem) => {
                        const $tval = elem?.target?.value
                        setFieldType($tval)
                    }} aria-describedby='type_field'>
                        {Object.entries(typeFields).map(([key, value]) => (
                            <option value={key} selected={(fieldInfo?.type == key)}>{value}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                {((fieldType == 'dropdown') || (fieldType == 'dropdown_multi') || (fieldType === 'checkbox') || (fieldType === 'radio')) ? 
                <Form.Group controlId="validataionDropDownValues" className='mt-3'>
                    <Form.Label>Values</Form.Label>
                    <div className='parents-appended mb-2 d-flex gap-2 flex-column'>
                        {multiValuesElement.map((value, index) => (
                            <InputGroup className="">
                                <Form.Control required={true} defaultValue={value} type='text' name='options[]' maxLength={40}/>
                                <Button disabled={(index === 0)} onClick={() => removeMultiValues(index)} variant="outline-danger" id="button-addon1">
                                    <IconTrash size={16}/>
                                </Button>
                            </InputGroup>
                        ))}
                    </div>
                    <div className='mt-0'>
                        <a role='button' onClick={(el) => {
                            appendMultiValues()
                        }} className='btn btn-outline-secondary btn-sm'>Add More Value</a>
                    </div>
                </Form.Group> : <></>}

                {((fieldType == 'rating') || (fieldType == 'years')) ? 
                <Form.Group controlId="validataionDropDownValues" className='mt-3'>
                    <Form.Label>Values</Form.Label>
                    <InputGroup className="">
                        <Form.Control defaultValue={fieldInfo?.min_value} required={true} type='number' name='min_range' placeholder='Min' maxLength={40}/>
                        <Form.Control defaultValue={fieldInfo?.max_value} required={true} type='number' name='max_range' placeholder='Max' maxLength={40}/>
                    </InputGroup>
                </Form.Group> : <></>}

            </div>
            <div className='p-3 pt-0 mt-3'>
                <Button type='submit' name='save_add_audience' value={'1'} className='btn btn-primary btn-md'>
                    Add Field
                </Button>
            </div>
        </Form>
    </div></>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(EditorCustomField);