import React, {useEffect, useState} from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ClassicRoutes from './routes/Classic';
import AuthRoutes from './routes/AuthRoutes';
import "bootstrap/js/src/collapse";
import ScrollToTop from './utils/ScrollToTop';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [getLocalStorageDone, setGetLocalStorageDone] = useState('')

  // console.log('ENVVITALS', process.env.REACT_APP_URL_VITALS_API)

  useEffect(() => {
    // Check if the user is logged in (e.g., by checking if a token exists in local storage)
    const rgd_token = localStorage.getItem('rgd_token')
    const svc_token = localStorage.getItem('svc_token')

    if(!rgd_token && !svc_token){
      setIsLoggedIn(false)
    }else{
      setIsLoggedIn(true)
    }
  }, []);

  useEffect(() => {
    setGetLocalStorageDone(new Date().getTime().toString())
  }, [])

  return (
    <>
      {(getLocalStorageDone) ?   <BrowserRouter>
        <BrowserRouter >
          <ScrollToTop>
            <Switch>
            {isLoggedIn ? (
            <>
              {/* <Redirect exact from="/" to="/dashboard" /> */}
              <Route path="/" render={(props) => <ClassicRoutes {...props} />} />
            </>
          ) : (
           <>
            <Redirect to="/auth/login" />
            <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
           </>
          )}
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </BrowserRouter> : <></>}
     
    </>
  );
}

export default App;
