import { func } from "prop-types";
import React, {useEffect, useState} from "react";
import { Col, Pagination, Form, Row } from "react-bootstrap";

const TableFooter = ({ range, setPage, setPerPage, page, slice, totalRows, perpage, paginatorSize }) => {


    const totalPages = Math.ceil(totalRows / perpage);
    const rangeNew = [];
    for (let i = 1; i <= totalPages; i++) {
        rangeNew.push(i)
    }

    const [refreshData, setRefreshDate] = useState(null)

    useEffect(() => {

    }, [refreshData]);

    const handleNext = () => {
        if (slice.length > 1 && page !== rangeNew.length) {
            setPage(page + 1);
        }
    }

    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    return (
        <Row className="ps-3 pe-3 row align-items-center">
            {/* <Col>
                {slice.length} of {totalRows}
            </Col> */}
            <Col>
                <div className="d-flex gap-2 align-items-center">
                    <label>Show</label>
                    <Form.Select onChange={(el) => {
                        setPerPage(el?.target?.value)
                        setRefreshDate(new Date().getTime())
                    }} size='sm' role="button" className="w-60p">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Select>
                    <label>entries</label>
                </div>
            </Col>
            <Col>
                <Pagination size={paginatorSize} className='custom-pagination pagination-simple d-flex justify-content-end mb-0'>
                    <Pagination.Prev onClick={handlePrev} disabled={page === 1} >
                        <i className="ri-arrow-left-s-line" />
                    </Pagination.Prev>
                    {rangeNew.map((el, index) => (
                        <Pagination.Item data-pagesel={el} onClick={(cuk) => setPage(el)} active={page === el} key={index}>{el}</Pagination.Item>
                    ))}
                    {/* <Pagination.Item active>{page}</Pagination.Item> */}
                    <Pagination.Next onClick={handleNext} disabled={page === rangeNew.length} >
                        <i className="ri-arrow-right-s-line" />
                    </Pagination.Next>
                </Pagination>
            </Col>
        </Row>
    );
};

export default TableFooter;