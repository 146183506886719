import Dashboard from "../views/Dashboard";
import BroadcastIndex from "../views/Broadcasts/Index";
import BroadcastReport from "../views/Broadcasts/report";

import AudiencesIndex from "../views/Audiences/Index";
import ListViewAudience from "../views/Audiences/Lists/view";
import AddNewAudiences from "../views/Audiences/import";

import QuarantineIndex from "../views/Audiences/Quarantine/Index";

import IdentifyImportedFile from "../views/Audiences/Identify";

import TemplatesIndex from "../views/Templates/Index";
import TemplatesEditorIndex from "../views/Templates/Editor/index";

import FormsIndex from "../views/Forms/Index";
import FormsEditorIndex from "../views/Forms/Editor/Index";

import AutorespondersIndex from "../views/Autoresponders/Index";
import LandingPagesIndex from "../views/LandingPages/Index";
import AutomationsIndex from "../views/Automations/Index";
import EmailSendersIndex from "../views/EmailSenders/Index";
import IntegrationsIndex from "../views/Integrations/Index";
import AccountSettingsIndex from "../views/AccountSettings/Index";

import EditorCustomField from "../views/CustomFields/Editor/index";

import Error404 from "../views/Authentication/Error404/Error404";
import Error503 from "../views/Authentication/Error503/Error503";

import LogIn from "../views/Authentication/LogIn/Login/Login";
import LoginSimple from "../views/Authentication/LogIn/LoginSimple/index";
import LoginClassic from "../views/Authentication/LogIn/LoginClassic/index";
import { exact } from "prop-types";

export const routes = [

    // { path: '/', exact: true, component: Dashboard },
    { path: 'dashboard', exact: true, component: Dashboard },
    
    { path: 'broadcast', exact: true, component: BroadcastIndex },
    { path: 'broadcast-view/:id', exact: true, component: BroadcastReport },

    { path: 'audiences', exact: true, component: AudiencesIndex },
    { path: 'list-view/:id', exact: true, component: ListViewAudience },
    { path: 'create-audience/:id', exact: true, component: AddNewAudiences },

    { path: 'indentify-fields/:id', exact: true, component: IdentifyImportedFile },

    { path: 'templates', exact: true, component: TemplatesIndex },
    { path: 'templates/editor/:id', exact: true, component: TemplatesEditorIndex },

    { path: 'forms', exact: true, component: FormsIndex },
    { path: 'form/editor', exact: true, component: FormsEditorIndex },

    { path: 'autoresponders', exact: true, component: AutorespondersIndex },
    { path: 'landing-pages', exact: true, component: LandingPagesIndex },
    { path: 'automations', exact: true, component: AutomationsIndex },
    { path: 'emailsenders', exact: true, component: EmailSendersIndex },
    { path: 'integrations', exact: true, component: IntegrationsIndex },
    { path: 'accounts-settings', exact: true, component: AccountSettingsIndex },

    { path: 'create-field', exact: true, component: EditorCustomField },
    { path: 'edit-field/:id', exact: true, component: EditorCustomField },

    { path: 'quarantine/:id', exact: true, component: QuarantineIndex},
   
    { path: 'error-404', exact: true, component: Error404 },
]

export const authRoutes = [
    { path: '/login', exact: true, component: LoginClassic },
    { path: '/error-503', exact: true, component: Error503 },
]