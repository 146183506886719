import React, { useEffect } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Calendar } from 'react-feather';
import ActiveUserCard from './ActiveUserCard';
import AudienceReviewCard from './AudienceReviewCard';
import CustomerTable from './CustomerTable';
import ReturningCustomersCard from './ReturningCustomersCard';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';


const Dashboard = ({ navCollapsed, toggleCollapsedNav }) => {
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Dashboard',
            actions: null
        }));
        toggleCollapsedNav(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Container>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Dashboard);