import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const LadingPagesIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const playerRef = useRef(null);

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Landing Pages',
            actions: {
                title: 'New Landing Page',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: null
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Container>
                <div className='d-flex flex-column justify-content-center gap-3  align-items-center'>
                    <Player ref={playerRef} size={130} icon={ SMILEJson } onComplete={() => playerRef.current?.playFromBeginning()}/>
                    <h5 className='mb-0'>You have not sent any landing pages yet</h5>
                    <p>Don't be shy to create new landing pages here. Everything that's been sent will appear here.</p>
                    <a className=' btn btn-primary' onClick={''}>New Landing Page</a>
                </div>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(LadingPagesIndex);