import React, { useEffect } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect,useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';

const IntegrationsIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Integrations',
            actions: null
        }));
        toggleCollapsedNav(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Container>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(IntegrationsIndex);