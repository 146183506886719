import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Modal, Form, InputGroup, Nav, Row, Table, Tab, Card, Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { IconChevronLeft, IconChecks, IconAlertTriangle, IconCircleCheckFilled } from '@tabler/icons-react'
import { updateTopNavTitle } from '../../../redux/action/Header';
import classNames from 'classnames';

import {useDropzone} from 'react-dropzone'
import Swal from 'sweetalert2';

import YogiTabler from '../../../components/@yogi-data-table';
import { colSubscribers } from '../Index/AudicencesTableData';

import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkProgressBar from '../../../components/@hk-progressbar/HkProgressBar';

const IdentifyImportedFile = ({ navCollapsed, toggleCollapsedNav }) => {
    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const [pageTitle, setPageTitle] = useState('...')

    const [totalMapped, setTotalMapped] = useState(0)
    const [totalUnMapped, setTotalUnMapped] = useState(0)

    const [mappings, setMappings] = useState({})
    const [doneMappings, setDoneMappings] = useState({})
    const [customFields, setCustomFields] = useState([])
    const [activeTable, setActiveTable] = useState('mapped')

    const [typeoffile, setTypeOfFile] = useState('')
    const [progressUpload, setProgressUpload] = useState(0)
    const [totalRowUploaded, setTotalRowUploaded] = useState(0)

    const [contactsId, setContactsId] = useState('')

    const [modalProgressImportState, setModalProgressImportState] = useState(false)

    const [headInfo, setHeadInfo] = useState([])

    useEffect(() => {
        const head_info = localStorage.getItem('head_info')
        try{
            setHeadInfo(JSON.parse(head_info))
        }catch(erx){}
    },[])

//handleChooseField(el, key, 'fullname')

    const doneUploading = async(elem) => {
        history.push('/list-view/' + contactsId);
    }

    const uploadAudiences = async(elem) => {

        setModalProgressImportState(true)

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'import/save/mapping';

        var _mappings = mappings;
        const newObjSend = {}
        Object.keys(_mappings).forEach(key => {
            var idex = 0;
            _mappings[key]?.value?.forEach(val => {
                console.log('K: ' + key + ', V: '+ val + ', IDEX: ' + idex);
        
                if (!newObjSend[idex]) {
                    newObjSend[idex] = {}; // Initialize newObjSend[idex] if it's undefined
                }
                newObjSend[idex][key] = val;
                
                idex++;
            });
        });

        let totalDataUpload = Object.keys(newObjSend).length;
        var totalDoneUpload = 0;

        Object.keys(newObjSend).forEach(async(key) => {
            const newObjSendData = newObjSend[key]

            // console.log('newObjSendData', newObjSendData)

            const apiParams = {
                'id': id,
                'mappings': newObjSendData
            }

            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){
                totalDoneUpload++;

                //sum progress
                var _progressUpload = (totalDoneUpload / totalDataUpload);
                _progressUpload = Math.round(_progressUpload * 100);

                setTotalRowUploaded(totalDoneUpload)
                setProgressUpload(_progressUpload)
            }
        });
    }

    const cancelMappings = async(elem) => {
        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'import/cancel/mapping';

        const apiParams = {
            'id': id
        }
    
        const bodySend = JSON.stringify(apiParams);
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodySend // Sending current page to fetch data accordingly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const result = await response.json();

        if(result?.status){

            history.push('/create-audience/' + result?.id);

        }
    }

    const handleChooseField = async(elem, prevKey, newKey) => {
        // Create a copy of doneMappings to modify it
        const updatedMappings = { ...doneMappings };
        // Remove the previous key
        delete updatedMappings[prevKey];
        // Set the new key
        updatedMappings[newKey] = 1;
        // Update the state with the modified mappings
        setDoneMappings(updatedMappings);

        const totalMappedNew = Object.keys(updatedMappings).filter(key => !key.includes("unmapping_")).length;
        const totalUnMappedNew = Object.keys(updatedMappings).filter(key => key.includes("unmapping_")).length;

        setTotalMapped(totalMappedNew)
        setTotalUnMapped(totalUnMappedNew)

        //Update Mappings
        if (mappings.hasOwnProperty(prevKey)) {
            mappings[newKey] = mappings[prevKey];
            delete mappings[prevKey];
            setMappings({...mappings}); // Update state with the modified object
        }
    }

    const getInfo = async(id) => {

        if((typeof id != 'undefined') && (id != null) && (id != '')){
            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'import/lists/mapping';

            const apiParams = {
                'id': id
            }
        
            const bodySend = JSON.stringify(apiParams);
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: bodySend // Sending current page to fetch data accordingly
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const result = await response.json();

            if(result?.status){

                // console.log('Mappsings', result?.info?.mappings)

                setContactsId(result?.info?.contacts_id)
                setPageTitle(result?.info?.title)
                setMappings(result?.info?.mappings)
                setCustomFields(result?.info?.custom_fields)

                setTotalMapped(result?.info?.totals?.mapped)
                setTotalUnMapped(result?.info?.totals?.unmapped)

                setTypeOfFile(result?.info?.typeoffile)

                const doneMappingsTmp = []
                Object.keys(result?.info?.mappings).map(key => {
                    doneMappingsTmp[key] = 1;
                });

                setDoneMappings(doneMappingsTmp)

            }else{
                history.push('/audiences');
            }
        }
        
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            breadcrumb: [{
                title: pageTitle,
                is_active: false,
                className: 'fs-5 text-dark',
                onClick: (elem) => {

                }
            }, {
                title: 'Identify Your Columns',
                is_active: true,
                className: 'fs-5 text-muted',
                onClick: (elem) => {
                    history.goBack()
                }
            }],
            actions: {
                title: 'Back',
                classlists: 'flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-sm',
                icons: <IconChevronLeft size={16}/>,
                onclick: (el) => {
                    history.goBack()
                }
            }
        }));
    }, [pageTitle])

    useEffect(() => {
        toggleCollapsedNav(false);
        getInfo(id)
    }, [])

    return (<>
    <div className='mt-2'>
        <div className='p-3 pt-0 pb-0'>
            <b className='fs-5 text-dark mb-2 d-block'>Identify your columns</b>
            <p className='text-muted'>Map your columns to identifiers to upload your audiences. Your data will be hashed before it's uploaded.</p>
        </div>
        <div className='d-flex gap-3 align-items-center ps-3 pe-3 mt-3'>
            <a role='button' onClick={(el) => setActiveTable('mapped')} className={classNames("flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-md", ((activeTable == 'mapped') ? 'text-dark border-dark' : 'text-muted border-light'))}>
                <span className='gap-2'>
                    <IconChecks size={16} className='text-success'/>
                    <span className='d-inline fs-7'>Mapped ({totalMapped})</span>
                </span>
            </a>
            <a role='button' onClick={(el) => setActiveTable('unmapped')} className={classNames("flex-shrink-0 d-lg-inline-block d-none btn btn-outline-secondary btn-md", ((activeTable == 'unmapped') ? 'text-dark border-dark' : 'text-muted border-light'))}>
                <span className='gap-2'>
                    <IconAlertTriangle size={16} className='text-warning'/>
                    <span className='d-inline fs-7'>Configure ({totalUnMapped})</span>
                </span>
            </a>
        </div>

        <div className='mt-3 ms-3 me-3'>
            <Table
            className='hk-data-table'
            striped={false}
            bordered={false}
            hover={true}
            responsive={false}
            >
                <thead>
                    <tr>
                        <th className='text-dark'>Map Column to Identifier</th>
                        <th className='text-dark'>Formatting Guidelines</th>
                        <th className='text-dark'>Example</th>
                    </tr>
                </thead>
                <tbody>
                     {Object.keys(mappings).filter(key => ((activeTable == 'mapped') ? !key.includes('unmapping_') : key.includes('unmapping_'))).map(key => (
                        <tr>
                            <td>
                                <div className='d-flex gap-2 align-items-center'>
                                    <div className='d-flex gap-1 flex-column  w-250p'>
                                        {(key == 'email') ? <b className='fs-6 fw-normal text-dark'>Email Address</b> : ''}
                                        {(key == 'fullname') ? <b className='fs-6 fw-normal text-dark'>Fullname</b> : ''}
                                        {(key.includes('unmapping_')) ? <b className='fs-6 fw-normal text-dark'>{mappings[key]?.title}</b> : ''}
                                        <div className='d-flex gap-0 flex-column'>
                                            {mappings[key]?.value?.slice(0, 3).map((valMaps, keyMaps) => (
                                                <span className='d-block fs-7 text-muted'>{valMaps}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center w-170p'>
                                        {(key.includes('unmapping_')) ? <IconAlertTriangle size={20} className='text-warning'/> : <IconCircleCheckFilled size={20} className='text-success'/>}
                                        {(key == 'email') ? <p className='fs-7'>Email</p> : <Dropdown className="ms-0">
                                            <Dropdown.Toggle size="sm" variant="outline-secondary" className="flex-shrink-0 d-lg-inline-block d-none">
                                                {(key == 'email') ? 'Email': ''}
                                                {(key == 'fullname') ? 'Fullname' : ''}
                                                {(key.includes('unmapping_')) ? 'Do Not Upload' : ''}
                                                {customFields?.map((cf_val, cf_idx) => {
                                                    if(key == cf_val?.id){
                                                        return (<>{cf_val?.title}</>)
                                                    }
                                                })}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu flip={true}>
                                                <Dropdown.Item onClick={(el) => handleChooseField(el, key, 'donotupload')} active={(key.includes('unmapping_'))} className='fs-7'>Do Not Upload</Dropdown.Item>
                                                <Dropdown.Header className='fs-8'>MAIN COLUMNS</Dropdown.Header>
                                                <Dropdown.Item onClick={(el) => handleChooseField(el, key, 'email')} active={(key == 'email')} disabled={(doneMappings?.hasOwnProperty('email') && (key != 'email'))} className='fs-7'>Email</Dropdown.Item>
                                                <Dropdown.Item onClick={(el) => handleChooseField(el, key, 'fullname')} active={(key == 'fullname')} disabled={(doneMappings?.hasOwnProperty('fullname') && (key != 'fullname'))} className='fs-7'>Fullname</Dropdown.Item>
                                                <Dropdown.Header className='fs-8'>CUSTOM COLUMNS</Dropdown.Header>
                                                {customFields?.map((cf_val, cf_idx) => (
                                                    <Dropdown.Item onClick={(el) => handleChooseField(el, key, cf_val?.id)} className='fs-7' active={(key == cf_val?.id)} disabled={(doneMappings?.hasOwnProperty(cf_val?.id) && (key != cf_val?.id))}>{cf_val?.title}</Dropdown.Item>
                                                ))}
                                               
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    </div>
                                </div>
                            </td>
                            {(key == 'email') ? <>
                            <td>
                                <p className='fs-7 text-muted'>All universal email formats are accepted.<br/>Just make sure the entire emails are valid.</p>
                            </td>
                            <td>
                                <div className='d-flex gap-1 flex-column'>
                                    <span className='d-block fs-7 text-muted'>aniesbaswedan@gmail.com</span>
                                    <span className='d-block fs-7 text-muted'>prabowosubianto@gmail.com</span>
                                    <span className='d-block fs-7 text-muted'>ganjarpranowo@yahoo.com</span>
                                </div>
                            </td>
                            </>:<></>}

                            {(key == 'fullname') ? <>
                            <td>
                                <p className='fs-7 text-muted'>Full Name must contains only letters,<br/>any numbers and symbols are not accepted.</p>
                            </td>
                            <td>
                                <div className='d-flex gap-1 flex-column'>
                                    <span className='d-block fs-7 text-muted'>Anies Baswedan</span>
                                    <span className='d-block fs-7 text-muted'>Prabowo Subianto</span>
                                    <span className='d-block fs-7 text-muted'>Ganjar Pranowo</span>
                                </div>
                            </td>
                            </>:<></>}

                            {(key.includes('unmapping_') || key.includes('customfl_')) ? <>
                            <td>
                                <p className='fs-7 text-muted'>We accept any letters, numbers, symbols<br/>or any special characterfor custom column.</p>
                            </td>
                            <td>
                                <div className='d-flex gap-1 flex-column'>
                                    <span className='d-block fs-7 text-muted'>#INV001</span>
                                    <span className='d-block fs-7 text-muted'>20 Januari 2024</span>
                                    <span className='d-block fs-7 text-muted'>Rp25.000,-</span>
                                </div>
                            </td>
                            </>:<></>}
                            
                        </tr>
                    ))}
                    
                </tbody>

            </Table>
        </div>

        <div className='mt-5 ms-3 me-3 d-flex gap-3'>
            <a role='button' onClick={(elem) => uploadAudiences(elem)} className='btn btn-primary btn-md'>Upload Audiences</a>
            <a role='button' onClick={(elem) => cancelMappings(elem)} className='btn btn-light btn-md'>Cancel</a>
        </div>

    </div>

        <Modal size={'lg'} centered show={modalProgressImportState} onHide={() => setModalProgressImportState(false)}>
            <Modal.Body>
                
                <div className="inner-content">
                    <div className="area-uploading-contacts">
                        <div className="area-loading-headsup">
                            <img className="bg-animate-loading" src="https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/9_window.svg" alt="window"/>
                            {(typeoffile == 'xlsx') ? <img className="animate-moving" src="https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/1_excel.svg"/> : <></>}
                            {(typeoffile == 'csv') ? <img className="animate-moving" src="https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/2_csv.svg"/> : <></>}
                            {(typeoffile == 'txt') ? <img className="animate-moving" src="https://semutganteng.fra1.digitaloceanspaces.com/StaticAssets/3_txt.svg"/> : <></>}
                        </div>
                    </div>
                    <div className="progress-area">
                        {(progressUpload >= 100) ? <b className="headsup-header">Upload is completed</b> : <b className="headsup-header">Upload in Progress</b>}
                        {(progressUpload >= 100) ? <p className="headsup-subheader">Your contacts is successfully uploaded into your list.</p> : <p className="headsup-subheader">Your data is being hashed. Don’t close this window until it’s fully uploaded, or it may stop this process.</p>}
                        <div style={{position: 'relative'}}>
                            <b className="progress-percentage" style={{color: ((progressUpload >= 50) ? '#f8f8f8' : '#337f86')}}>{progressUpload}%</b>
                            <div className="progress">
                                <div className="determinate" style={{width: progressUpload+'%'}}></div>
                            </div>
                        </div>
                        <small><span className="rows-uploaded">{totalRowUploaded}</span> rows uploaded </small>
                    </div>
                    <div className='mt-3 d-flex align-items-center justify-content-center'>
                        <a role='button' onClick={(elem) => doneUploading(elem)} className={classNames('btn btn-light btn-md w-200p', ((progressUpload >= 100) ? '' : 'disabled'))}>Finish</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(IdentifyImportedFile);